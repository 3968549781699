import {Component, Input, OnChanges} from '@angular/core';
import {myBadges_my_badges} from '../../../modules/achievements/components/badges-list/__generated__/myBadges';
import { MatomoHelperService } from 'src/app/services/matomo-helper.service';
import { Apollo, gql } from 'apollo-angular';
import { myQuery } from 'src/app/services/authenticationService/authentication.service.gql';
import { UpdateUser } from '../../graph-ql/user.gql';
import { UpdateUserGQL, UpdateUserGQLVariables } from '../../graph-ql/__generated__/UpdateUserGQL';
import { myBadgesQuery } from 'src/app/modules/achievements/components/badges-list/badges-list.component.gql';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnChanges {
  @Input() public badge?: myBadges_my_badges | any;
  @Input() public showDescription: boolean = false;
  @Input() public hideTooltip: boolean = false;

  public badgeClass: string = '';

  badgeImagePath = '';

  constructor(
    private matomo: MatomoHelperService,
    private apollo: Apollo
  ){}

  ngOnChanges() {
   const hostUrl = window.location.hostname;
    this.badgeImagePath = this.badge.badge.imagePath ?  this.badge.badge.imagePath.replace('minio', hostUrl) : null
    if (this.badge) {
      if (this.badge.isUnlocked) {
        this.badgeClass = this.badge.badge.badgeType.toLowerCase();
      } else {
        this.badgeClass = 'notAchieved';
      }
    }
  }

  selectBadge(){
    this.updateUser();
  }

  public updateUser() {
    this.matomo.logSubmitForm('my-onya-profile');
    this.apollo
      .mutate<UpdateUserGQL, UpdateUserGQLVariables>({
        mutation: UpdateUser,
        variables: {
          data: {
            selectedBadgeId: this.badge?.badge?.id
          },
        },
        refetchQueries: [
          {
            query: myQuery,
          },
          {
            query: myBadgesQuery
          }
        ],
      })
      .subscribe(() => {});
  }
}
