<div class="name-points-column">
  <div class="user-icon-name-column">
    <div class="user-icon-wrap">
      <ng-container *ngIf="entry.user">
        <div *ngIf="entry.user.profileImage; else UserHasNoProfileImage">
          <img class="profile-image" [src]="entry.user.profileImage.size320x320Link" />
        </div>
        <ng-template #UserHasNoProfileImage>
          <fa-icon class="profile-no-image" [icon]="['fas', 'user-circle']" size="2x"></fa-icon>
        </ng-template>
      </ng-container>
    </div>
    <div class="user-name">
      {{ entry.user.displayAlias }}
    </div>
  </div>
</div>
<div class="card-main-title">
  <div *ngIf="entry?.trigger?.__typename === 'Level'">
    <span>{{ 'TIMELINE.ITEMS.CONTENT_TITLE.LEVEL' | translate }}</span>
    <fa-icon icon="level-up-alt" size="2x"></fa-icon>
  </div>
  <div *ngIf="entry?.trigger?.__typename === 'Quest'">
    <span>{{ 'TIMELINE.ITEMS.CONTENT_TITLE.QUEST' | translate }}</span>
    <fa-icon icon="map-marked-alt" size="2x"></fa-icon>
  </div>
  <div *ngIf="entry?.trigger?.__typename === 'Activity'">
    <span>{{ 'TIMELINE.ITEMS.CONTENT_TITLE.ACTIVITY' | translate }}</span>
    <fa-icon icon="edit" size="2x"></fa-icon>
  </div>
  <div *ngIf="entry?.trigger?.__typename === 'Badge'">
    <span>{{ 'TIMELINE.ITEMS.CONTENT_TITLE.BADGE' | translate }}</span>
    <fa-icon icon="boxes" size="2x"></fa-icon>
  </div>
  <div *ngIf="entry?.trigger?.__typename === 'TeamPlanning'">
    <span>{{ 'TIMELINE.ITEMS.CONTENT_TITLE.PLANNING' | translate }}</span>
    <fa-icon icon="calendar-alt" size="2x"></fa-icon>
  </div>
</div>
