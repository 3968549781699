<div>
  <mat-form-field>
    <div>
      <textarea
        matInput
        matTextareaAutosize
        matAutosizeMinRows="5"
        type="text"
        [(ngModel)]="innerValue"
        (input)="notifyOnChange(innerValue)"
        (change)="notifyOnChange(innerValue)"
        (focusin)="notifyOnTouch()"
        [placeholder]="elementName"
        [disabled]="isDisabled"
        [errorStateMatcher]="errorStateMatcher"
        #textinput
      ></textarea>
      <app-info-button *ngIf="tooltip.length > 0" [tooltip]="tooltip"></app-info-button>
    </div>
    <mat-error *ngFor="let err of errorMapper">
      <ng-container *ngIf="touched && errors && errors[err.error]">
        {{ err.message }}
      </ng-container>
    </mat-error>
  </mat-form-field>
</div>
