import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {VideoHelpDialogComponent} from './video-help-dialog/video-help-dialog.component';

@Component({
  selector: 'app-video-help',
  templateUrl: './video-help.component.html',
  styleUrls: ['./video-help.component.scss'],
})
export class VideoHelpComponent implements OnInit {
  @Input() videoUrl!: string;
  @Input() videoNumber!: number;

  constructor() {}

  ngOnInit() {}
}
