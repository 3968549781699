import {Component, OnInit, Input, Output, EventEmitter, forwardRef, Injector, OnDestroy} from '@angular/core';
import {AppControlValueAccessorDirective} from '../../../form-elements/components/AppControlValueAccessor';
import {NG_VALUE_ACCESSOR, FormGroup, FormControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatomoHelperService} from '../../../../../services/matomo-helper.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaginationComponent),
      multi: true,
    },
  ],
})
export class PaginationComponent extends AppControlValueAccessorDirective implements OnInit, OnDestroy {
  @Input() totalPageControl!: FormControl;
  @Input() currentPageControl!: FormControl;

  @Output() changePage = new EventEmitter();

  pages: any[] = [];

  constructor(protected injector: Injector, private matomo: MatomoHelperService) {
    super();
  }

  ngOnInit() {
    this.totalPageControl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.createPages();
    });
  }

  get current() {
    return this.currentPageControl.value;
  }

  get maxPages() {
    return this.totalPageControl.value;
  }

  writeValue(): void {}

  setPage(page: number) {
    this.matomo.logSubmitForm('paginator', 'page to: ' + page);
    this.currentPageControl.setValue(page);
  }

  createPages() {
    this.pages = [];
    for (let i = 1; i <= this.maxPages; i++) {
      this.pages.push(i);
    }
  }
}
