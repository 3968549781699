import {Component, OnInit} from '@angular/core';
import {AbstractNavButtonDirective} from '../abstract-nav-button';

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
})
export class NavButtonComponent extends AbstractNavButtonDirective implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
