import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GameTenantKind} from '__generated__/globalTypes';

@Component({
  selector: 'app-level-and-points-display',
  templateUrl: './level-and-points-display.component.html',
  styleUrls: ['./level-and-points-display.component.scss'],
})
export class LevelAndPointsDisplayComponent implements OnInit {
  @Input() gameTenantKind: GameTenantKind | undefined;
  @Input() totalPoints!: number;
  @Input() currentLevelName!: string;

  @Output() levelClick: EventEmitter<void> = new EventEmitter();
  @Output() pointClick: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
