import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

export class SimplePhoneNumber {
  constructor(public countryCode: string, public phoneNumber: string, public id: string | undefined) {}
}

@Component({
  selector: 'app-telephone-input',
  templateUrl: './telephone-input.component.html',
  styleUrls: ['./telephone-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TelephoneInputComponent),
      multi: true,
    },
  ],
})
export class TelephoneInputComponent extends AppControlValueAccessorDirective implements OnInit {
  @Input()
  innerValue: SimplePhoneNumber = new SimplePhoneNumber('', '', undefined);

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {}

  writeValue(obj: any): void {
    this.innerValue = obj;
  }
}
