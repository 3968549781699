import {ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm, ValidationErrors} from '@angular/forms';
import {Injector, Input, Directive} from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {ErrorMapper} from './ErrorMapper';
import {SubscribedComponent} from '../../../misc/SubscribedComponent';

@Directive()
export abstract class AppControlValueAccessorDirective extends SubscribedComponent implements ControlValueAccessor {
  private onChangeListeners: Function[] = [];
  private onTouchListeners: Function[] = [];
  errorStateMatcher: ErrorStateMatcher;

  protected abstract injector: Injector;

  @Input()
  public isDisabled!: boolean;

  @Input()
  public elementName!: string;

  @Input()
  errorMapper!: ErrorMapper[];

  protected constructor() {
    super();
    this.errorStateMatcher = new AppCvaErrorStateMatcher(this);
  }

  abstract writeValue(obj: any): void;

  public registerOnChange(fn: Function): void {
    this.onChangeListeners.push(fn);
  }

  public notifyOnChange(obj: any) {
    for (const changeListener of this.onChangeListeners) {
      changeListener(obj);
    }
  }

  public registerOnTouched(fn: Function): void {
    this.onTouchListeners.push(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public notifyOnTouch() {
    for (const touchListener of this.onTouchListeners) {
      touchListener();
    }
  }

  public get errors(): ValidationErrors | null {
    return this.injector.get<any>(NgControl as any).errors;
  }

  public get dirty(): boolean {
    try {
      const control = this.injector.get<any>(NgControl as any);

      return control.dirty || (control._parent && control._parent.dirty);
    } catch {
      return false;
    }
  }

  public get touched(): boolean {
    try {
      const control = this.injector.get<any>(NgControl as any);

      return control.touched;
    } catch {
      return false;
    }
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class AppCvaErrorStateMatcher implements ErrorStateMatcher {
  constructor(private cva: AppControlValueAccessorDirective) {}

  isErrorState(_control: FormControl | null, _form: FormGroupDirective | NgForm | null): boolean {
    return this.cva.touched && !!this.cva.errors;
  }
}
