import {EventEmitter, Input, Output, Directive} from '@angular/core';

@Directive()
export class AbstractButtonDirective {
  @Output()
  onClick: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  disabled: boolean = false;

  constructor() {}
}
