import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';

export enum WindowMode {
  DESKTOP,
  MOBILE,
  TABLET,
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private showSidebarSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly showSidebar: Observable<boolean> = this.showSidebarSubject.asObservable();

  private windowModeSubject = new BehaviorSubject<WindowMode | undefined>(undefined);
  public readonly windowMode: Observable<WindowMode | undefined> = this.windowModeSubject.asObservable();

  constructor(public breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      /**
     DANGER:
     If you want to change these constants,
     you need to change the costants in the "~src/css/responsive-consts" as well.
     */
      .observe(['(max-width: 480px)', '(max-width: 1024px)', '(min-width: 1025px)'])
      .subscribe(() => {
        this.setState();
      });
  }

  public ShowSideNav() {
    this.showSidebarSubject.next(true);
  }

  public HideSideNav() {
    this.showSidebarSubject.next(false);
  }

  private setState() {
    /**
     DANGER:
     If you want to change these constants,
     you need to change the costants in the "~src/css/responsive-consts" as well.
     */
    if (this.breakpointObserver.isMatched('(max-width: 480px)')) {
      this.windowModeSubject.next(WindowMode.MOBILE);
      this.showSidebarSubject.next(false);
    } else if (this.breakpointObserver.isMatched(['(max-width: 1024px)'])) {
      this.windowModeSubject.next(WindowMode.TABLET);
      this.showSidebarSubject.next(true);
    }
    if (this.breakpointObserver.isMatched('(min-width: 1025px)')) {
      this.windowModeSubject.next(WindowMode.DESKTOP);
      this.showSidebarSubject.next(true);
    }
  }
}
