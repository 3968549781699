<mat-toolbar class="main-toolbar">
  <div class="title-sidenav-toggle">
    <app-icon-button class="sidebar-button profile-image-button" (onClick)="toggleSideNav()">
      <ng-container *ngIf="authService.user | async as user">
        <div class="img-wrapper" *ngIf="user.profileImage; else UserHasNoProfileImage">
          <img class="profile-image" [src]="user.profileImage.size320x320Link" />
        </div>
        <ng-template #UserHasNoProfileImage>
          <fa-icon class="profile-no-image" icon="user-circle"></fa-icon>
        </ng-template>
      </ng-container>
      <app-icon-important *ngIf="notificationService.HasNotifications | async"></app-icon-important>
    </app-icon-button>
    <div class="logo-link" routerLink="/my-onya/timeline" (click)="trackNavigation('/my-onya/timeline')">
      <div>
        <img class="logo" src="../../../../assets/logos/logo-without-text.svg" />
      </div>
      <span *ngIf="(layout.windowMode | async) !== windowMode.MOBILE; else mobile">Onya</span>
      <ng-template #mobile>
        <span class="mobile-title">{{ title | async | translate }}</span>
      </ng-template>
    </div>
  </div>

  <div class="buttons-right">
    <ng-template [cdkPortalOutlet]="extraButtonPortal"></ng-template>
    <app-icon-button routerLink="search" (click)="trackNavigation('search')">
      <fa-icon icon="search" class="icon" size="lg"></fa-icon>
    </app-icon-button>
    <app-icon-button routerLink="my-notifications" (click)="trackNavigation('my-notifications')">
      <span *ngIf="(notifyService.notify$ | async)?.hasNewNotifications" class="notifications__indicator"></span>
      <fa-icon [icon]="['far', 'bell']" size="lg"></fa-icon>
    </app-icon-button>
    <app-icon-button routerLink="activity/new" (click)="trackNavigation('activity/new')">
      <fa-icon icon="edit" class="icon" size="lg"></fa-icon>
    </app-icon-button>
    <div class="team">
      <app-menu *ngIf="(authService.user | async)?.team == null">
        <app-icon-button>
          <fa-icon icon="users" class="icon"></fa-icon>
          <fa-icon class="icon" icon="plus"></fa-icon>
        </app-icon-button>

        <app-menu-content>
          <app-no-team-menu></app-no-team-menu>
        </app-menu-content>
      </app-menu>
      <app-icon-button *ngIf="(authService.user | async)?.team != null" routerLink="my-team" (click)="trackNavigation('my-team')">
        <fa-icon icon="users" class="icon" size="lg"></fa-icon>
      </app-icon-button>
    </div>
  </div>

  <ng-content></ng-content>
</mat-toolbar>
