<div class="info-separator-row">
  <div class="date-ways-col">
    <div class="year-display" *ngIf="showYear">
      {{ entry.createdAt | date: 'yyyy' }}
    </div>
    <div class="date-wrap">
      {{ entry.createdAt | date: 'd. MMMM' }}
    </div>
    <div class="time-wrap">
      {{ entry.createdAt | date: 'H:mm' }}
    </div>
    <app-five-ways-list-borderless dir="rtl" [fiveWays]="fiveWays"></app-five-ways-list-borderless>
  </div>
  <div class="separator"></div>
</div>
<div class="activity-card" ngClass="{{ entry?.trigger?.__typename }}">
  <app-timeline-item-header-row [entry]="entry"></app-timeline-item-header-row>
  <app-timeline-item-main-content
    [entry]="entry"
    [badge]="badge"
    [pointReward]="pointReward"
    [duration]="duration"
    [reward]="reward"
  ></app-timeline-item-main-content>
  <div class="with-row" *ngIf="withUsers.length > 0">
    {{ 'TIMELINE.ITEMS.CONTENT_TEXTS.WITH' | translate }}
    <app-picked-team-member-list [pickedMembers]="withUsers"></app-picked-team-member-list>
  </div>
  <div class="hashtag-row">
    <ng-container *ngFor="let hashtag of hashtags">#{{ hashtag.text }}</ng-container>
  </div>
  <div class="activity-image-wrap" *ngFor="let image of timelineImages">
    <img class="activity-image" (click)="ImagePopup(image.originalLink)" [src]="image.size800x800Link" />
  </div>
  <div class="like-comment-row" [class.interaction-disabled]="interactionDisabled">
    <div class="onya-button" (click)="like()">
      <img [ngClass]="liked ? '' : 'not-liked'" src="./assets/logos/logo-without-text.svg" />
      <div>
        {{ onyaAmount }}
      </div>
      <div [ngPlural]="onyaAmount">
        <ng-template ngPluralCase="=0">
          {{ 'TIMELINE.ITEMS.ONYAS' | translate }}
        </ng-template>
        <ng-template ngPluralCase="=1">
          {{ 'TIMELINE.ITEMS.ONYA' | translate }}
        </ng-template>
        <ng-template ngPluralCase="other">
          {{ 'TIMELINE.ITEMS.ONYAS' | translate }}
        </ng-template>
      </div>
    </div>
    <div class="comment-button" (click)="trackNavigation('/timelineItem')" [routerLink]="['/timelineItem', entry.id]">
      <fa-icon [icon]="['far', 'comment']" size="2x"></fa-icon>
      <div>
        <span>{{ commentAmount }}</span>
      </div>
      <div [ngPlural]="commentAmount">
        <ng-template ngPluralCase="=0">
          {{ 'TIMELINE.ITEMS.COMMENTS.COMMENTS' | translate }}
        </ng-template>
        <ng-template ngPluralCase="=1">
          {{ 'TIMELINE.ITEMS.COMMENTS.COMMENT' | translate }}
        </ng-template>
        <ng-template ngPluralCase="other">
          {{ 'TIMELINE.ITEMS.COMMENTS.COMMENTS' | translate }}
        </ng-template>
      </div>
    </div>
  </div>
  <app-activity-visibility-picker 
    *ngIf="enableVisibilitySelector && entry?.trigger?.__typename === 'TeamPlanning'"
    [formControl]="visibilitySelectorControl"
    (ngModelChange)="changeVibility()"
  ></app-activity-visibility-picker>
</div>
