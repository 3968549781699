import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    data.yesTextKey = !!data.yesTextKey ? data.yesTextKey : 'GENERAL.YES';
    data.noTextKey = !!data.noTextKey ? data.noTextKey : 'GENERAL.NO';
  }

  public confirm() {
    this.dialogRef.close(true);
  }

  public cancel() {
    this.dialogRef.close(false);
  }
}

export interface ConfirmDialogData {
  titleTextKey: string;
  bodyTextKey: string;
  yesTextKey?: string;
  noTextKey?: string;
}
