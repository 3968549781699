import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ControlValueAccessorAdapter} from '../../modules/form-elements/components/ControlValueAccessorAdapter';
import {My_my} from '../../../services/authenticationService/__generated__/My';
import {AuthenticationService} from '../../../services/authenticationService/authentication.service';
import {
  FileReporter,
  FileReporterCompleteStatus,
} from '../../../modules/activity/components/new/images-form-group/images-form-group.component';
import {ImageType, UploadService} from '../../../modules/activity/components/new/upload.service';
import {SnackBarService} from '../../../services/snackbar-service/snackbar-service';
import {MatDialog} from '@angular/material/dialog';
import {AvatarPickerComponent} from './avatar-picker/avatar-picker.component';
import {first} from 'rxjs/operators';
import {getAllAvatarImages_allAvatarImages} from './avatar-picker/__generated__/getAllAvatarImages';

// The inner value of this control is either a FileReporter or null.
@Component({
  selector: 'app-image-cropper-form-control',
  templateUrl: './image-cropper-form-control.component.html',
  styleUrls: ['./image-cropper-form-control.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ImageCropperFormControlComponent), multi: true}],
})
export class ImageCropperFormControlComponent extends ControlValueAccessorAdapter implements OnDestroy {
  @ViewChild('File') filesInput: any;
  imageChangedEvent: any;
  cropperReady = false;
  innerValue?: FileReporter;
  FileReporterCompleteStatus = FileReporterCompleteStatus;
  public user!: My_my;

  public showEditor = false;

  public isUploading: boolean = false;

  @Input() avatarpickerEnabled: boolean = false;
  @Output() showEditorView: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isStillUploading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private uploadService: UploadService,
    private notificationService: SnackBarService,
    public authenticationService: AuthenticationService,
    public dialog: MatDialog,
  ) {
    super();
  }

  addImage() {
    this.filesInput.nativeElement.click();
    this.showEditorView.emit(this.showEditor);
  }

  cancelImage() {
    if (!this.innerValue) {
      return;
    }

    this.showEditor = false;
    this.showEditorView.emit(this.showEditor);
    this.innerValue = undefined;
    this.imageChangedEvent = undefined;
    this.filesInput.nativeElement.value = '';
  }

  onFileAdded(event: any): void {
    if (!event.target.files || !event.target.files.length) {
      return;
    }

    this.showEditor = true;
    this.showEditorView.emit(this.showEditor);
    this.imageChangedEvent = event;
  }

  uploadImage(): void {
    if (!this.innerValue) {
      return;
    }

    this.isUploading = true;
    this.isStillUploading.emit(true);
    this.showEditor = false;
    this.showEditorView.emit(this.showEditor);
    this.uploadService.uploadBlobByObjectUrl(this.innerValue.url, ImageType.profile).subscribe(
      (x) => {
        if (!this.innerValue) {
          return;
        }

        this.innerValue.progress = x.progress;
        this.innerValue.complete = !!x.complete ? FileReporterCompleteStatus.Success : FileReporterCompleteStatus.Pending;
        this.innerValue.id = x.id && x.id[0];
        if (!!x.complete) {
          this.isUploading = false;
          this.isStillUploading.emit(false);
        }

        // Emit a change of the `formControl` to the consumer
        // Update the inner form
        this.notifyChange(this.innerValue);
      },
      () => {
        this.isUploading = false;
        this.isStillUploading.emit(false);
        if (!this.innerValue) {
          return;
        }

        this.innerValue.complete = FileReporterCompleteStatus.Error;
        this.notifyChange(null);
      },
    );
  }

  imageCropped(event: any) {
    // Cleanup previous crop
    this.disposeImage();

    const url = window.URL.createObjectURL(event.file);
    this.innerValue = {
      url,
      fileName: '',
      progress: 0,
      complete: FileReporterCompleteStatus.NotInitated,
    };
  }

  imageLoaded() {
    this.cropperReady = true;
  }

  loadImageFailed() {
    this.notificationService.error('IMAGE_EDITOR.LOAD_IMAGE_FAIL');
  }

  ngOnDestroy(): void {
    this.disposeImage();
  }

  private disposeImage(): void {
    if (!this.innerValue) {
      return;
    }
    window.URL.revokeObjectURL(this.innerValue.url);
  }

  writeValue(_obj: any): void {}

  openAvatarChooser() {
    this.dialog
      .open(AvatarPickerComponent)
      .afterClosed()
      .pipe(first())
      .subscribe((result: { avatar: getAllAvatarImages_allAvatarImages, color: string }) => {
        if (!result.avatar.id) {
          return;
        }
        const newInnerValue: FileReporter = {
          id: result.avatar.id,
          complete: FileReporterCompleteStatus.Success,
          progress: 100,
          fileName: result.avatar.id,
          url: result.avatar.originalLink ? result.avatar.originalLink : '',
          color: result.color
        };
        this.innerValue = newInnerValue;
        this.notifyChange(this.innerValue);
      });
  }
}

export const pendingUploadErrorKey = 'uploading';
export const pendingUploadValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value as FileReporter | null;
  if (value && value.complete === FileReporterCompleteStatus.Pending) {
    return {[pendingUploadErrorKey]: true};
  }
  return null;
};
