<div class="block-text-font">
  <ng-content></ng-content>
</div>
<div class="slider-container">
  <div class="slider-left-label display-alias-font">{{ 'ACTIVITIES.IMPROVEMENT_LEFT_LABEL' | translate }}</div>
  <div class="slider-right-label display-alias-font">{{ 'ACTIVITIES.IMPROVEMENT_RIGHT_LABEL' | translate }}</div>
  <div class="slider">
    <mat-button-toggle-group [(ngModel)]="innerValue" (ngModelChange)="modelChanged()">
      <mat-button-toggle [value]="1">1</mat-button-toggle>
      <mat-button-toggle [value]="2">2</mat-button-toggle>
      <mat-button-toggle [value]="3">3</mat-button-toggle>
      <mat-button-toggle [value]="4">4</mat-button-toggle>
      <mat-button-toggle [value]="5">5</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<mat-error *ngFor="let err of errorMapper">
  <ng-container *ngIf="touched && errors && errors[err.error]">{{ err.message }}</ng-container>
</mat-error>
