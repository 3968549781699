import {gql, Apollo} from 'apollo-angular';
import {Component, OnInit} from '@angular/core';

import {BehaviorSubject} from 'rxjs';
import {AuthenticationService} from '../../../services/authenticationService/authentication.service';

import {SubscribedComponent} from '../../misc/SubscribedComponent';
import {takeUntil, first} from 'rxjs/operators';
import {InvitationAcceptance, InvitationAcceptanceVariables} from './__generated__/InvitationAcceptance';
import {Router} from '@angular/router';
import {pendingInvitationsGQL, pendingInvitationsGQL_my_pendingInvitations} from './__generated__/pendingInvitationsGQL';

const pendingInvitations = gql`
  query pendingInvitationsGQL {
    my {
      id
      email
      gender
      loginName
      description
      pendingInvitations {
        id
        createdAt
        team {
          displayAlias
          teamname
        }
      }
    }
  }
`;

const invitationAcceptance = gql`
  mutation InvitationAcceptance($acceptInvitation: Boolean!, $teamInvitationId: ID!) {
    invitationAcceptance(acceptInvitation: $acceptInvitation, teamInvitationId: $teamInvitationId)
  }
`;

@Component({
  selector: 'app-pending-invitations',
  templateUrl: './pending-invitations.component.html',
  styleUrls: ['./pending-invitations.component.scss'],
})
export class PendingInvitationsComponent extends SubscribedComponent implements OnInit {
  public invitations: BehaviorSubject<pendingInvitationsGQL_my_pendingInvitations[]> = new BehaviorSubject<
    pendingInvitationsGQL_my_pendingInvitations[]
  >([]);

  constructor(private apollo: Apollo, private authService: AuthenticationService, private router: Router) {
    super();

    this.apollo
      .watchQuery<pendingInvitationsGQL>({query: pendingInvitations})
      .valueChanges.pipe(takeUntil(this.onDestroy))
      .subscribe((ret) => {
        if (ret.data && ret.data && ret.data.my) {
          this.invitations.next(ret.data.my.pendingInvitations);
        }
      });
  }

  ngOnInit() {}

  public acceptPending(i: number, accept: boolean) {
    this.apollo
      .mutate<InvitationAcceptance, InvitationAcceptanceVariables>({
        mutation: invitationAcceptance,
        variables: {
          acceptInvitation: accept,
          teamInvitationId: this.invitations.value[i].id,
        },
        refetchQueries: [{query: pendingInvitations}],
      })
      .pipe(first())
      .subscribe(() => {
        this.authService.refreshUser();
        this.authService.user.pipe(first()).subscribe(() => {
          setTimeout(() => {
            this.router.navigate(['my-team/home']);
          }, 50);
        });
      });
  }
}
