import {ControlValueAccessor} from '@angular/forms';

export abstract class ControlValueAccessorAdapter implements ControlValueAccessor {
  // Listeners at not preset at construction and will be set at some point
  // later in the Angular lifecycle.
  private onChangeListener?: (x: any) => void;
  private onTouchListener?: () => void;

  private _isDisabled!: boolean;

  get isDisabled(): boolean {
    return this._isDisabled;
  }

  abstract writeValue(obj: any): void;

  registerOnChange(fn: any): void {
    this.onChangeListener = fn;
  }

  public notifyChange(obj: any) {
    if (this.onChangeListener === undefined) {
      return;
    }
    this.onChangeListener(obj);
  }

  registerOnTouched(fn: any): void {
    if (this.onTouchListener === undefined) {
      return;
    }
    this.onTouchListener = fn;
  }

  public notifyTouch() {
    if (this.onTouchListener === undefined) {
      return;
    }
    this.onTouchListener();
  }

  public setDisabledState(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }
}
