<mat-form-field class="example-chip-list">
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let item of itemList" [selectable]="selectable" [removable]="removable" (removed)="remove(item)">
      {{ item[displayField] }}
      <i *ngIf="removable" class="fas fa-trash"></i>
    </mat-chip>
    <input
      placeholder="New fruit..."
      #fruitInput
      [formControl]="autoCompControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of filteredData | async" [value]="item">
      {{ item[displayField] }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
