import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {FiveWaysToWellbeing, GameTenantKind} from '../../../../../../../__generated__/globalTypes';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';

@Component({
  selector: 'app-wellbeing-type-picker',
  templateUrl: './wellbeing-type-picker.component.html',
  styleUrls: ['./wellbeing-type-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WellbeingTypePickerComponent),
      multi: true,
    },
  ],
})
export class WellbeingTypePickerComponent extends AppControlValueAccessorDirective implements OnInit {
  public wellbeingEnum = FiveWaysToWellbeing;
  public wellbeingTypes: FiveWaysToWellbeing[] = [];
  @Input() gameTenantKind: GameTenantKind | undefined;

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {}

  public toggleType(type: FiveWaysToWellbeing) {
    if (this.isDisabled) {
      return;
    }
    if (this.wellbeingTypes.includes(type)) {
      this.wellbeingTypes = this.wellbeingTypes.filter((t: FiveWaysToWellbeing) => {
        return t !== type;
      });
    } else {
      this.wellbeingTypes.push(type);
    }
    this.notifyOnChange(this.wellbeingTypes);
    this.notifyOnTouch();
  }

  writeValue(obj: FiveWaysToWellbeing[]): void {
    if (obj) {
      this.wellbeingTypes = obj;
    } else {
      this.wellbeingTypes = [];
    }
  }
}
