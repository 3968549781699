import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(public snackBar: MatSnackBar, private translateService: TranslateService) {}

  success(message: string) {
    this.openSnackBar(message);
  }

  info(message: string) {
    this.openSnackBar(message);
  }

  warning(message: string) {
    this.openSnackBar(message);
  }

  error(message: string) {
    this.openSnackBar(message);
  }

  private openSnackBar(message: string): void {
    this.translateService
      .get(message)
      .pipe(first())
      .subscribe((translatedMessage: string) => {
        const config = new MatSnackBarConfig();
        config.horizontalPosition = 'center';
        config.verticalPosition = 'bottom';
        config.duration = 5000;
        this.snackBar.open(translatedMessage, undefined, config);
      });
  }
}
