<app-chip
  [ngClass]="gameTenantKind === 'Company' ? 'be-active company-bg' : 'be-active school-bg'"
  *ngIf="!isDisabled || wellbeingTypes?.includes(wellbeingEnum.BeActive)"
  (click)="toggleType(wellbeingEnum.BeActive)"
  [matTooltip]="'WELLBEINGWAYS.TOOLTIP.BEACTIVE' | translate"
  matTooltipPosition="above"
  matRipple
  [matRippleDisabled]="isDisabled"
  [class.is-active]="wellbeingTypes?.includes(wellbeingEnum.BeActive)"
  [class.is-disabled]="isDisabled"
>
  <app-icon-be-active></app-icon-be-active>
  Bewegen
  <fa-icon class="info" [icon]="['fas', 'info-circle']"></fa-icon>
</app-chip>
<app-chip
  [ngClass]="gameTenantKind === 'Company' ? 'learn company-bg' : 'learn school-bg'"
  *ngIf="!isDisabled || wellbeingTypes?.includes(wellbeingEnum.KeepLearning)"
  (click)="toggleType(wellbeingEnum.KeepLearning)"
  [matTooltip]="'WELLBEINGWAYS.TOOLTIP.LEARNING' | translate"
  [matRippleDisabled]="isDisabled"
  matTooltipPosition="above"
  matRipple
  [class.is-disabled]="isDisabled"
  [class.is-active]="wellbeingTypes?.includes(wellbeingEnum.KeepLearning)"
>
  <app-icon-learn></app-icon-learn>
  Lernen
  <fa-icon class="info" [icon]="['fas', 'info-circle']"></fa-icon>
</app-chip>
<app-chip
  [ngClass]="gameTenantKind === 'Company' ? 'give company-bg' : 'give school-bg'"
  *ngIf="!isDisabled || wellbeingTypes?.includes(wellbeingEnum.Give)"
  (click)="toggleType(wellbeingEnum.Give)"
  [matTooltip]="'WELLBEINGWAYS.TOOLTIP.GIVE' | translate"
  [matRippleDisabled]="isDisabled"
  matTooltipPosition="above"
  matRipple
  [class.is-disabled]="isDisabled"
  [class.is-active]="wellbeingTypes?.includes(wellbeingEnum.Give)"
>
  <app-icon-give></app-icon-give>
  Geben
  <fa-icon class="info" [icon]="['fas', 'info-circle']"></fa-icon>
</app-chip>
<app-chip
  [ngClass]="gameTenantKind === 'Company' ? 'connect company-bg' : 'connect school-bg'"
  *ngIf="!isDisabled || wellbeingTypes?.includes(wellbeingEnum.Connect)"
  (click)="toggleType(wellbeingEnum.Connect)"
  [matTooltip]="'WELLBEINGWAYS.TOOLTIP.CONNECT' | translate"
  [matRippleDisabled]="isDisabled"
  matRipple
  matTooltipPosition="above"
  [class.is-disabled]="isDisabled"
  [class.is-active]="wellbeingTypes?.includes(wellbeingEnum.Connect)"
>
  <app-icon-connect></app-icon-connect>
  Vernetzen
  <fa-icon class="info" [icon]="['fas', 'info-circle']"></fa-icon>
</app-chip>
<app-chip
  [ngClass]="gameTenantKind === 'Company' ? 'take-notice company-bg' : 'take-notice school-bg'"
  *ngIf="!isDisabled || wellbeingTypes?.includes(wellbeingEnum.TakeNotice)"
  (click)="toggleType(wellbeingEnum.TakeNotice)"
  [matTooltip]="'WELLBEINGWAYS.TOOLTIP.TAKE_NOTICE' | translate"
  [matRippleDisabled]="isDisabled"
  matRipple
  matTooltipPosition="above"
  [class.is-disabled]="isDisabled"
  [class.is-active]="wellbeingTypes?.includes(wellbeingEnum.TakeNotice)"
>
  <app-icon-take-notice></app-icon-take-notice>
  Achtsam sein
  <fa-icon class="info" [icon]="['fas', 'info-circle']"></fa-icon>
</app-chip>
<mat-error *ngFor="let err of errorMapper">
  <ng-container *ngIf="touched && errors && errors[err.error]">{{ err.message }}</ng-container>
</mat-error>
