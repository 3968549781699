<mat-chip-list>
  <mat-chip [disableRipple]="true" class="image-name-wrap" *ngFor="let member of pickedMembers" [disabled]="true">
    <img *ngIf="member.profileImage; else UserHasNoProfileImage" [src]="member.profileImage.size320x320Link" class="profile-image" />
    <ng-template #UserHasNoProfileImage>
      <fa-icon class="user-icon" icon="user-circle"></fa-icon>
    </ng-template>
    <div class="name-wrap">
      {{ member.displayAlias }}
    </div>
  </mat-chip>
</mat-chip-list>
