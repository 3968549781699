import {Component, Input, OnInit} from '@angular/core';
import {VideoHelpDialogComponent} from '../video-help-dialog/video-help-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-video-help-button',
  templateUrl: './video-help-button.component.html',
  styleUrls: ['./video-help-button.component.scss'],
})
export class VideoHelpButtonComponent implements OnInit {
  @Input() videoUrl!: string;
  @Input() videoNumber!: number;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  showVideoHelpDialog() {
    this.dialog.open(VideoHelpDialogComponent, {
      width: '450px',
      data: {
        videoUrl: this.videoUrl,
      },
    });
  }
}
