<div *ngIf="recentEntries?.length > 0" class="timeline-container">
  <div class="filter-row" [formGroup]="formFilter">
    <app-menu dir="rtl">
      <app-icon-button>
        <fa-icon [icon]="['far', 'filter']"></fa-icon>
      </app-icon-button>
      <app-menu-content (click)="$event.stopPropagation()" dir="ltr">
        <app-filter-activities formControlName="discriminator"></app-filter-activities>
      </app-menu-content>
    </app-menu>
    <app-text-input-cva 
      formControlName="search" 
      [elementName]="placeHolders[formFilter?.value?.discriminator]"
    ></app-text-input-cva>
  </div>
  <ng-container 
    *ngIf="formFilter.value.search === '' && formFilter.value.discriminator === 'All'; then allEntries else filterEntries"
  ></ng-container>
  <ng-template #allEntries>
    <app-timeline-display 
      [entries]="recentEntries"
    ></app-timeline-display>
  </ng-template>
  <ng-template #filterEntries>
      <ng-container *ngIf="(mainLeaderBoard | async) as entries">
        <app-timeline-display 
          [entries]="entries"
        ></app-timeline-display>
      </ng-container>
  </ng-template>
</div>
