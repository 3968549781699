import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HasPermissionDirective} from './directives/has-permission.directive';
import {ConfirmClickDirective} from './directives/confirm-click.directive';

const directives = [HasPermissionDirective, ConfirmClickDirective];

@NgModule({
  declarations: [...directives],
  imports: [CommonModule],
  exports: [...directives],
})
export class DirectivesModule {}
