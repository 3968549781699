import {gql} from 'apollo-angular';

export const GetAutocompleteHashtagQuery = gql`
  query GetAutocompleteHashtags {
    allAutocompleteHashtags {
      id
      text
    }
  }
`;
