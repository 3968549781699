<div [formGroup]="controlContainer.control" novalidate class="tooltip-row">
  <div formArrayName="files">
    <ng-container *ngFor="let fileCtrl of filesForm.controls; let i = index">
      <app-image-form-control [formControlName]="i" (remove)="removeFileCtrl(i)"></app-image-form-control>
    </ng-container>

    <div>
      <button mat-button type="button" (click)="addFiles()">{{ 'ACTIVITIES.ADD_IMAGE' | translate }}</button>
    </div>
  </div>

  <app-info-button [tooltip]="'ACTIVITIES.ADD_IMAGE_TOOLTIP' | translate"></app-info-button>
</div>

<!--Hidden File Input-->
<input #File (change)="onFileAdded($event)" style="display: none" type="file" multiple accept="image/*" />
