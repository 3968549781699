import {Component, OnInit} from '@angular/core';
import {AbstractButtonDirective} from '../abstract-button';

@Component({
  selector: 'app-button, button[app-button]',
  exportAs: 'appButton',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends AbstractButtonDirective implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
