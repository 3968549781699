<div class="title-font">
  {{ 'IOS_INTALL_HINT.TITLE' | translate }}
</div>
<div class="text block-text-font">
  <div>{{ 'IOS_INTALL_HINT.HINT_1' | translate }}</div>
  <img src="./assets/ios_install_hint/share.svg" />
  <div>{{ 'IOS_INTALL_HINT.HINT_2' | translate }}</div>
</div>
<div>
  <img src="./assets/ios_install_hint/add_to_homescreen.jpg" />
</div>
<div>
  <app-raised-button (onClick)="closeDialog()">
    {{ 'IOS_INTALL_HINT.CLOSE' | translate }}
  </app-raised-button>
</div>
