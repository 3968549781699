import {Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output} from '@angular/core';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';
import {filter, map, startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipListComponent),
      multi: true,
    },
  ],
})
export class ChipListComponent extends AppControlValueAccessorDirective implements OnInit {
  @Input()
  visible = true;

  @Input()
  selectable = true;

  @Input()
  removable = true;

  @Input()
  addOnBlur = false;

  @Input()
  displayField: string = '';

  @Input()
  filterFunc!: (input: string) => Observable<any[]>;

  itemList: any[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];

  @Output()
  NewChip: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  RemoveChip: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  SelectChip: EventEmitter<any> = new EventEmitter<any>();

  autoCompControl = new FormControl();
  filteredData: Observable<any[]>;

  constructor(protected injector: Injector) {
    super();
    this.filteredData = this.autoCompControl.valueChanges.pipe(
      startWith(null),
      filter((x: string | null) => {
        return x ? x.length > 2 : false;
      }),
      switchMap((searchString: string | null) => {
        return searchString ? this._filter(searchString) : [];
      }),
    );
  }

  ngOnInit() {}

  writeValue(obj: any[]): void {
    this.itemList = obj;
  }

  add(event: MatChipInputEvent): void {
    this.NewChip.emit(event.value);

    const input = event.input;
    if (input) {
      input.value = '';
    }
  }

  remove(value: any): void {
    this.RemoveChip.emit(value);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.SelectChip.emit(event.option.value);
  }

  private _filter(value: string): Observable<any[]> {
    return this.filterFunc(value).pipe(
      map((data: any[]) => {
        return data.filter((item: any) => {
          return !this.itemList.some((listItem) => item[this.displayField] === listItem[this.displayField]);
        });
      }),
    );
  }
}
