import {AuthenticationService} from 'src/app/services/authenticationService/authentication.service';
import {GameTenantKind} from './../../../../../__generated__/globalTypes';
import {Component} from '@angular/core';
import {MatomoHelperService} from '../../../services/matomo-helper.service';

@Component({
  selector: 'app-no-team-menu',
  templateUrl: './no-team-menu.component.html',
  styleUrls: ['./no-team-menu.component.scss'],
})
export class NoTeamMenuComponent {
  public gameTenantKind: GameTenantKind | undefined;
  constructor(private matomo: MatomoHelperService, authService: AuthenticationService) {
    this.gameTenantKind = authService.gameTenantKind;
  }

  trackNavigation(toUrl: string) {
    this.matomo.logNavigation('no-team-menu', toUrl);
  }
}
