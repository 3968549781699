import {Component, forwardRef, Injector, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';

@Component({
  selector: 'app-wellbeing-improvement-picker',
  templateUrl: './wellbeing-improvement-picker.component.html',
  styleUrls: ['./wellbeing-improvement-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WellbeingImprovementPickerComponent),
      multi: true,
    },
  ],
})
export class WellbeingImprovementPickerComponent extends AppControlValueAccessorDirective implements OnInit {
  public innerValue: number | undefined;

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {}

  writeValue(obj: any): void {
    if (obj) {
      this.innerValue = obj;
    }
  }

  modelChanged() {
    this.notifyOnTouch();
    this.notifyOnChange(this.innerValue);
  }
}
