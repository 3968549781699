<div class="name-edit-button-row">
  <app-section>
    <app-section-header>
      {{ team?.displayAlias }}
    </app-section-header>
    <div class="inner-content">@{{ team?.teamname }}</div>
  </app-section>
  <ng-content></ng-content>
</div>

<div class="image-wrapper">
  <div *ngIf="team?.profileImage; else NoImage">
    <img class="profile-image" [src]="team?.profileImage.size640x640Link" alt="{{ team?.teamname }}" />
  </div>
  <ng-template #NoImage>
    <div class="profile-image">
      <fa-icon class="profile-no-image" icon="image"></fa-icon>
    </div>
  </ng-template>
</div>

<div class="text">
  <h3>
    {{ 'EDIT_TEAM.DESCRIPTION' | translate }}
  </h3>
  <div>{{ team?.description || 'MY_ONYA.NO_DESCRIPTION' | translate }}</div>
</div>
<div class="text">
  <h3>
    {{ 'EDIT_TEAM.LOCATION' | translate }}
  </h3>
  <div>{{ team?.location || 'MY_ONYA.LOCATION' | translate }}</div>
</div>

<div class="text">
  <h3>
    {{ 'EDIT_TEAM.MEMBER_COUNT' | translate }}
  </h3>
  <div>{{ team?.memberCount }}</div>
</div>
