import {Component, Input, OnInit} from '@angular/core';
import {MyTimeline_my_timelineEntries} from '../../../../modules/my-onya/components/timeline/__generated__/MyTimeline';

@Component({
  selector: 'app-timeline-item-header-row',
  templateUrl: './timeline-item-header-row.component.html',
  styleUrls: ['./timeline-item-header-row.component.scss'],
})
export class TimelineItemHeaderRowComponent implements OnInit {
  @Input()
  entry!: MyTimeline_my_timelineEntries;

  constructor() {}

  ngOnInit() {}
}
