import {Component, OnInit, Input} from '@angular/core';
import {UnlockedInfotainments_my_unlockedInfotainments} from '../../../modules/achievements/components/rewards/__generated__/UnlockedInfotainments';
import {MatomoHelperService} from '../../../services/matomo-helper.service';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss'],
})
export class RewardComponent implements OnInit {
  @Input() public reward?: UnlockedInfotainments_my_unlockedInfotainments;
  @Input() public showDescription: boolean = false;

  public rewardClass: string = '';

  constructor(private matomo: MatomoHelperService) {}

  ngOnInit(): void {}

  openReward() {
    if (!this.reward || !this.reward.file || !this.reward.file.link) {
      return;
    }
    window.open(this.reward.file.link, '_blank');
    this.matomo.logNavigation('infotainment', this.reward.file.link);
  }
}
