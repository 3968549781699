<div>
  <ng-container *ngIf="authService.user | async as user">
    <mat-drawer-container class="drawer-container">
      <mat-drawer
        [ngClass]="gameTenantKind === 'Company' ? 'company-bg' : 'school-bg'"
        [mode]="isMobile ? 'over' : 'side'"
        [disableClose]="false"
        [autoFocus]="true"
        [opened]="SidebarIsOpened | async"
        (closedStart)="closeSideNav()"
        class="custom-nav-material-theme"
      >
        <div class="header">
          <div class="logged-in-user">
            <div class="user-icon-wrap">
              <div *ngIf="user.profileImage; else UserHasNoProfileImage">
                <img class="profile-image" [src]="user.profileImage.size320x320Link" [style.background-color]="user?.profileBackgroundColor" />
              </div>
              <ng-template #UserHasNoProfileImage>
                <fa-icon class="profile-no-image" icon="user-circle"></fa-icon>
              </ng-template>
              <div 
                class="user-badge badge-{{user?.badgeSelectedByUserId?.badge?.badgeType | lowercase }}"
                matTooltip="{{user?.badgeSelectedByUserId?.badge?.name}} - {{user?.badgeSelectedByUserId?.badge?.rarity}}" 
              >
                <img 
                    *ngIf="user?.badgeSelectedByUserId?.badge.imagePath; else icon"
                    [src]="user?.badgeSelectedByUserId?.badge.imagePath.replace('minio', hostUrl)" 
                    alt="" width="10px" height="10px"
                />
                <ng-template #icon>
                  <ng-container *ngIf="user?.badgeSelectedByUserId?.badge?.iconName === 'fly'; then brandIcon; else defaultIcon"></ng-container>
                  <ng-template #brandIcon>
                    <fa-icon [icon]="['fab', user?.badgeSelectedByUserId?.badge?.iconName]" size="1x"></fa-icon>
                  </ng-template>
                  <ng-template #defaultIcon>
                    <fa-icon [icon]="['fas', user?.badgeSelectedByUserId?.badge?.iconName]" size="1x"></fa-icon>
                  </ng-template>
                </ng-template>
              </div>
            </div>
            <div class="username-handle-col">
              <div class="user-handle">
                {{ (authService.user | async)?.displayAlias }}
              </div>
              <div class="display-alias-font">&#64;{{ (authService.user | async)?.loginName }}</div>
            </div>
            <div (click)="closeIfMobile('chats')" class="user-chats" [routerLink]="'chats'" routerLinkActive="active accent-font">
              <span *ngIf="(notifyService.notify$ | async)?.hasNewMessages" class="user-chat__indicator"></span>
              <a>
                <fa-icon [icon]="['far', 'envelope']" size="lg" class="user-chats"></fa-icon>
              </a>
            </div>
          </div>
          
          <app-level-and-points-display
            [gameTenantKind]="gameTenantKind"
            [totalPoints]="(authService.user | async)?.totalPoints"
            [currentLevelName]="(authService.user | async)?.currentLevel.name"
            (levelClick)="closeIfMobile('faq/levels')"
            (pointClick)="closeIfMobile('leaderboards')"
          ></app-level-and-points-display>
        </div>

        <div
          class="button-field"
          *ngIf="(instService.installPossible | async) && (layoutService.windowMode | async) !== windowMode.DESKTOP"
        >
          <app-nav-item-button (click)="instService.installEventSubj.value.prompt(); instService.used()" iconName="download">
            {{ 'PWA.INSTALL' | translate }}
          </app-nav-item-button>
        </div>

        <div class="button-field" *ngIf="platform.IOS && platform.SAFARI && !isInStandaloneMode">
          <app-nav-item-button (click)="showInstallHint()" iconName="download">
            {{ 'PWA.INSTALL' | translate }}
          </app-nav-item-button>
        </div>

        <div class="button-field important-info" *ngIf="notificationService.HasNotifications | async">
          <app-important-nav-item
            [gameTenantKind]="gameTenantKind"
            [ngClass]="gameTenantKind === 'Company' ? 'company-bg' : 'school-bg'"
            (click)="closeIfMobile('notifications')"
            routerLink="notifications"
            routerLinkActive="active-nav-item"
          >
            {{ 'TITLES.IMPORTANT' | translate }}
          </app-important-nav-item>
        </div>

        <div class="button-field">
          <app-nav-item (click)="closeIfMobile('activity')" routerLink="activity" iconName="edit" routerLinkActive="active-nav-item">
            {{ 'TITLES.NEW' | translate }}
          </app-nav-item>
        </div>

        <div class="button-field">
          <app-nav-item (click)="closeIfMobile('my-onya')" routerLink="my-onya" iconName="home" routerLinkActive="active-nav-item">
            {{ 'TITLES.MY_ONYA' | translate }}
          </app-nav-item>

          <app-nav-item (click)="closeIfMobile('my-team')" routerLink="my-team" iconName="users" routerLinkActive="active-nav-item">
            {{ 'TITLES.MY_TEAM' | translate }}
          </app-nav-item>
          <app-nav-item
            (click)="closeIfMobile('my-goals')"
            routerLink="my-goals"
            iconName="clipboard-list"
            routerLinkActive="active-nav-item"
          >
            {{ 'TITLES.MY_GOALS' | translate }}
          </app-nav-item>
        </div>

        <div class="button-field">
          <app-nav-item
            (click)="closeIfMobile('adventures')"
            routerLink="adventures"
            routerLinkActive="active-nav-item"
            iconName="map-marked-alt"
          >
            {{ 'TITLES.ADVENTURES' | translate }}
          </app-nav-item>
          <app-nav-item
            (click)="closeIfMobile('achievements')"
            routerLink="achievements"
            iconName="boxes"
            routerLinkActive="active-nav-item"
          >
            {{ 'TITLES.ACHIEVEMENTS' | translate }}
          </app-nav-item>
          <app-nav-item
            (click)="closeIfMobile('leaderboards')"
            routerLink="leaderboards"
            iconName="trophy"
            routerLinkActive="active-nav-item"
          >
            {{ 'TITLES.LEADERBOARDS' | translate }}
          </app-nav-item>
        </div>

        <div class="button-field">
          <app-nav-item (click)="closeIfMobile('settings')" routerLink="settings" iconName="cog" routerLinkActive="active-nav-item">
            {{ 'TITLES.SETTINGS' | translate }}
          </app-nav-item>
          <app-nav-item (click)="closeIfMobile('faq')" routerLink="faq" iconName="question" routerLinkActive="active-nav-item">
            {{ 'TITLES.FAQ' | translate }}
          </app-nav-item>
          <app-nav-item (click)="closeIfMobile('about')" routerLink="about" iconName="building" routerLinkActive="active-nav-item">
            {{ 'TITLES.ABOUT' | translate }}
          </app-nav-item>
        </div>
        <div class="button-field">
          <app-nav-item-button (click)="logout()" [routerLink]="'/auth/login'" iconName="sign-out-alt">
            {{ 'SETTINGS.LOGOUT' | translate }}
          </app-nav-item-button>
        </div>
      </mat-drawer>
      <mat-drawer-content>
        <div>
          <ng-content></ng-content>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </ng-container>
</div>
