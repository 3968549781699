<div class="wrapper">
  <mat-form-field>
    <mat-label>{{ 'ACTIVITY_VISIBILITY.SELECT_VISIBILITY' | translate }}:</mat-label>
    <mat-select [(ngModel)]="selectedVisibility" (selectionChange)="onVisibilityChange()">
      <mat-option *ngFor="let option of visibilityOptions" [value]="option.value">
        {{ option.label | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="selectedVisibility === 'SelectedMembers'">
    <ng-container *ngTemplateOutlet="membersList"></ng-container>
  </ng-container>
</div>

<ng-template #membersList>
  <div class="members-list"> 
    <ng-container *ngIf="(members$ | async) as members">
      <ng-container *ngIf="members && members.length > 0; else noMembers">
        <ng-container *ngFor="let member of members">
          <mat-checkbox (change)="onMemberCheckboxChange($event, member)" [checked]="isSelected(member)">
            {{ member.user.displayAlias }}
          </mat-checkbox>
        </ng-container>
      </ng-container>
      <ng-template #noMembers>
        <i>{{ 'ACTIVITY_VISIBILITY.EMPTY' | translate }}</i>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
