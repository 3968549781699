import {AfterViewInit, Component} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MatomoInjector, MatomoTracker} from 'ngx-matomo';
import {first, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {GameTenantKind} from '__generated__/globalTypes';
import {environment} from '../environments/environment';
import {AuthenticationService} from './services/authenticationService/authentication.service';
import {My_my} from './services/authenticationService/__generated__/My';
import {InstallService} from './services/installService/install.service';
import {LayoutService, WindowMode} from './services/layoutService/layout.service';
import {RerouteOnLoginService} from './services/reroute-on-login-service/reroute-on-login.service';

import * as CookieConsent from 'vanilla-cookieconsent';
import { MatomoHelperService } from './services/matomo-helper.service';
import { NotifyCheckerService } from './services/notify-checker/notify-checker.service';


export enum Layouts {
  LoggedIn,
  LoggedOut,
}

export interface InstallPromptEvent extends Event {
  prompt: () => void;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  public windowsMode = WindowMode;
  Layouts = Layouts;
  layout: Layouts = Layouts.LoggedOut;
  public gameTenantKind: GameTenantKind | undefined;
  public currentUser: Observable<My_my | null>;

  constructor(
    private translate: TranslateService,
    public authenticationService: AuthenticationService,
    public layoutService: LayoutService,
    private router: Router,
    private retoureService: RerouteOnLoginService,
    private installService: InstallService,
    private matomoHelperService: MatomoHelperService,
    private notifyCheckerService: NotifyCheckerService
  ) {
    this.translate.setDefaultLang('de');
    this.translate.use('de');
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installService.installEvent = e as InstallPromptEvent;
    });
    this.currentUser = this.authenticationService.user.pipe(
      first(),
      map((currentUser) => {
        if (currentUser != null) {
          this.authenticationService.gameTenantKind = currentUser.gameTenant?.gameTenantKind;
          this.gameTenantKind = currentUser.gameTenant?.gameTenantKind;
        }
        return currentUser;
      }),
    );
    this.router.events.subscribe((data) => {
      if (data instanceof ActivationEnd) {
        if (data.snapshot.root.firstChild) {
          this.layout = data.snapshot.root.firstChild.data.layout;
        }
      }
    });

    this.retoureService.saveUrlSnapshot();
  }
  
  ngAfterViewInit(): void {
    this.notifyCheckerService.initChecker();
    // Set cookie consent theme to build-in dark mode
    document.documentElement.classList.add('cc--darkmode');
    
    CookieConsent.run({
      revision: 1,
      mode: 'opt-in',
      categories: {
        necessary: {
          enabled: true,  // this category is enabled by default
          readOnly: true  // this category cannot be disabled
        },
        analytics: {
          enabled: true,
          services: {
            pk: {
              label: 'Matomo Analytics',
              onAccept: () => {
                this.matomoHelperService.initCookies();
              },
              onReject: async () => {                
                this.matomoHelperService.disableCookies();
              }
            }
          },
          autoClear: {
            cookies: [
              {
                name:  /^(_pk)/
              }
            ],
          },
        }
      },
      language: {
        default: 'de',
        autoDetect: 'browser',
        translations: {
          'de': '../assets/i18n/de.json'
        }
      },
      guiOptions: {
        consentModal: {
          layout: 'box',
          position: 'bottom left'
        },
        preferencesModal: {
          layout: 'box',
        }
      },
      onConsent: ({cookie })=> {
        console.warn(cookie);
        if(cookie.categories.includes('analytics')){
          this.matomoHelperService.initCookies();
        } 
      },
      onChange: ({ changedCategories }) => {        
        if(changedCategories.includes('analytics')){
          
          if(CookieConsent.acceptedCategory('analytics')){
            this.matomoHelperService.initCookies();
          }else{
            this.matomoHelperService.disableCookies();
          }
        }
      },
    });
  }
}
