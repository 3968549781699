<div>
  <mat-form-field>
    <input
      matInput
      type="text"
      [(ngModel)]="innerValue"
      (ngModelChange)="notifyOnChange(innerValue)"
      (click)="notifyOnTouch()"
      [placeholder]="elementName"
      [disabled]="isDisabled"
      [errorStateMatcher]="errorStateMatcher"
      #textinput
    />
    <mat-error *ngFor="let err of errorMapper">
      <ng-container *ngIf="touched && errors && errors[err.error]">{{ err.message }}</ng-container>
    </mat-error>
  </mat-form-field>
</div>
