import {gql} from 'apollo-angular';

export const getAllAvatarImagesQuery = gql`
  query getAllAvatarImages {
    allAvatarImages {
      id
      originalLink
    }
  }
`;
