<div class="name-edit-button-row">
  <app-section>
    <app-section-header>
      {{ user?.displayAlias }}
    </app-section-header>
    <div class="inner-content">&#64;{{ user?.loginName }}</div>
  </app-section>
<app-icon-button *ngIf="showEditButton" (click)="handleEdit()">
    <fa-icon [icon]="['fas', 'edit']"></fa-icon>
  </app-icon-button>
</div>
<div class="avatar-container">
  <div *ngIf="user?.profileImage; else UserHasNoProfileImage">
    <img class="profile-image" [src]="user?.profileImage.size320x320Link" [style.background-color]="user?.profileBackgroundColor" />
  </div>
  <ng-template #UserHasNoProfileImage>
    <div class="profile-image">
      <fa-icon [style.color]="user?.profileBackgroundColor || '#010328'" class="profile-no-image" icon="user-circle"></fa-icon>
    </div>
  </ng-template>
  <div 
    *ngIf="!showEditButton"
    class="user-badge badge-{{user?.badgeSelectedByUserId?.badge?.badgeType | lowercase }}"
    matTooltip="{{user?.badgeSelectedByUserId?.badge?.name}} - {{user?.badgeSelectedByUserId?.badge?.rarity}}" 
  >
      <img 
        *ngIf="user?.badgeSelectedByUserId?.badge.imagePath; else icon"
        [src]="user?.badgeSelectedByUserId?.badge.imagePath.replace('minio', hostUrl)" 
        alt="" width="31px" height="31px"
      />
    <ng-template  #icon>
      <ng-container *ngIf="user?.badgeSelectedByUserId?.badge?.iconName === 'fly'; then brandIcon; else defaultIcon"></ng-container>
      <ng-template #brandIcon>
        <fa-icon [icon]="['fab', user?.badgeSelectedByUserId?.badge?.iconName]" size="1x"></fa-icon>
      </ng-template>
      <ng-template #defaultIcon>
        <fa-icon [icon]="['fas', user?.badgeSelectedByUserId?.badge?.iconName]" size="1x"></fa-icon>
      </ng-template>
    </ng-template>
  </div>
</div>

<div class="text">
  <h3 class="section-header-font">
    {{ 'MY_ONYA.DESCRIPTION' | translate }}
  </h3>
  <div>{{ user?.description || 'MY_ONYA.NO_DESCRIPTION' | translate }}</div>
</div>

<div class="text">
  <h3 class="section-header-font">
    {{ 'MY_ONYA.LOCATION' | translate }}
  </h3>
  <div>{{ user?.location || 'MY_ONYA.NO_LOCATION' | translate }}</div>
</div>

<div class="text">
  <h3 class="section-header-font">
    {{ 'MY_ONYA.GENDER' | translate }}
  </h3>
  <div>{{ 'GENDER.' + user?.gender.toString().toUpperCase() | translate }}</div>
</div>

<div class="text">
  <h3 class="section-header-font">
    {{ 'MY_ONYA.VISIBILITY' | translate }}
  </h3>
  <div>{{ 'VISIBILITY.' + user?.visibility.toString().toUpperCase() | translate }}</div>
</div>

<app-level-and-points-display
  [gameTenantKind]="gameTenantKind"
  [currentLevelName]="user?.currentLevel.name"
  [totalPoints]="user?.totalPoints"
></app-level-and-points-display>

<div class="strength-meter" *ngIf="user?.levelProgress; else noNextLevel">
  <span class="title">{{ 'MY_ONYA.LEVEL_ADVANCE' | translate }}</span>
  <mat-slider
    [max]="user?.levelProgress?.nextLevelNeededPoints"
    [min]="user?.currentLevel?.minimumPointAmount"
    [thumbLabel]="true"
    [ngModel]="user?.totalPoints"
    [tabIndex]="-1"
    color="primary"
  ></mat-slider>
  <div class="description">
    <div>
      <div>{{ user?.currentLevel?.minimumPointAmount }} {{ 'MY_ONYA.POINTS' | translate }}</div>
      <div>{{ user?.currentLevel?.name }}</div>
    </div>
    <div>
      <div>{{ user?.levelProgress?.nextLevelNeededPoints }} {{ 'MY_ONYA.POINTS' | translate }}</div>
      <div>{{ user?.levelProgress?.name }}</div>
    </div>
  </div>
</div>

<ng-template #noNextLevel>Super, du hast das höchste Level erreicht!</ng-template>

<div class="amounts">
  <div>
    <fa-icon icon="thumbs-up"></fa-icon>
    <span class="points">
      {{ user?.amountAcquiredOnyas }}
    </span>
    <span class="name">
      {{ 'MY_ONYA.COLLECTED_ONYAS' | translate }}
    </span>
  </div>
  <div>
    <fa-icon icon="map"></fa-icon>
    <span class="points">
      {{ user?.amountDoneQuests }}
    </span>
    <span class="name">
      {{ 'MY_ONYA.PASSED_ADVENTURES' | translate }}
    </span>
  </div>
  <div>
    <fa-icon icon="trophy"></fa-icon>
    <span class="trophy">
      {{ user?.amountAcquiredBadges }}
    </span>
    <span class="name">
      {{ 'MY_ONYA.PRESERVED_TROPHIES' | translate }}
    </span>
  </div>
  <div>
    <fa-icon icon="book"></fa-icon>
    <span class="points">
      {{ user?.amountCreatedActivities }}
    </span>
    <span class="name">
      {{ 'MY_ONYA.RECORDED_ACTIVITIES' | translate }}
    </span>
  </div>
</div>
