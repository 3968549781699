import { Component, Injector, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { SubscribedComponent } from 'src/app/shared/misc/SubscribedComponent';
import { AppControlValueAccessorDirective } from 'src/app/shared/modules/form-elements/components/AppControlValueAccessor';

@Component({
  selector: 'app-visibility-picker',
  templateUrl: './visibility-picker.component.html',
  styleUrls: ['./visibility-picker.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => VisibilityPickerComponent), multi: true}],
})
export class VisibilityPickerComponent extends AppControlValueAccessorDirective implements SubscribedComponent {

  public Object = Object;
  public selectedVisibility!: string;

  constructor(protected injector: Injector, public authenticationService: AuthenticationService) {
    super();

    authenticationService.user.pipe(takeUntil(this.onDestroy)).subscribe((user) => {
      if (user && user.visibility) {
        this.selectedVisibility = user.visibility;
      } else {
        this.selectedVisibility = 'visible';
      }
    });
  }

  public selected() {
    this.notifyOnChange(this.selectedVisibility);
  }

  writeValue(obj: 'visible' | 'notvisible' | 'anonymous'): void {
    this.selectedVisibility = obj;
  }

}
