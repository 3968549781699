export enum OnyaNotificationType {
  EmailNotVerified,
  StartSurveyNotTaken,
  EndSurveyNotTaken,
  News,
}

export interface OnyaNotification<T> {
  Type: OnyaNotificationType;
  data: T;
}
