import {AfterViewInit, Component, OnInit, ViewContainerRef} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, first, map, startWith, takeUntil} from 'rxjs/operators';
import {LayoutService, WindowMode} from '../../../services/layoutService/layout.service';
import {AuthenticationService} from '../../../services/authenticationService/authentication.service';
import {ActivatedRoute, ActivationEnd, Router} from '@angular/router';
import {MatomoHelperService} from '../../../services/matomo-helper.service';
import {NotificationService} from '../../../modules/notifications/notification.service';
import {Portal, TemplatePortal} from '@angular/cdk/portal';
import {ToolbarService} from './toolbar.service';
import {SubscribedComponent} from '../../../shared/misc/SubscribedComponent';
import { NotifyCheckerService } from 'src/app/services/notify-checker/notify-checker.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent extends SubscribedComponent implements OnInit, AfterViewInit {
  SidebarIsOpened: Observable<boolean>;
  public windowMode = WindowMode;
  public title!: Observable<string>;
  extraButtonPortal!: Portal<any>;

  constructor(
    public layout: LayoutService,
    public authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private matomo: MatomoHelperService,
    public notificationService: NotificationService,
    private barService: ToolbarService,
    private _viewContainerRef: ViewContainerRef,
    public notifyService: NotifyCheckerService
  ) {
    super();
    this.SidebarIsOpened = layout.showSidebar;
  }

  ngOnInit() {
    this.title = this.router.events.pipe(
      filter((data): data is ActivationEnd => {
        return !!(data instanceof ActivationEnd && data.snapshot.root.firstChild);
      }),
      map((data) => {
        return data.snapshot.root.firstChild!.data.title;
      }),
      startWith(this.route.snapshot.firstChild!.data.title),
    );
  }

  ngAfterViewInit(): void {
    this.barService.templateChanged.pipe(takeUntil(this.onDestroy)).subscribe((template) => {
      if (template === null) {
        if (this.extraButtonPortal && this.extraButtonPortal.isAttached) {
          this.extraButtonPortal.detach();
        }
        return;
      }
      this.extraButtonPortal = new TemplatePortal(template, this._viewContainerRef);
    });
  }

  toggleSideNav() {
    this.matomo.logOtherAction('toolbar', 'toggle', 'sidenav');
    this.SidebarIsOpened.pipe(first()).subscribe((sidebarOpened: boolean) => {
      if (sidebarOpened) {
        this.layout.HideSideNav();
      } else {
        this.layout.ShowSideNav();
      }
    });
  }

  trackNavigation(url: string) {
    this.matomo.logNavigation('toolbar', url);
  }
}
