<div *ngIf="entry?.trigger?.__typename === 'Level'">
  <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.LEVEL_UP_BEFORE' | translate }}</span>
  <span class="entry-name">{{ entry?.trigger?.name }}</span>
  <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.LEVEL_UP_AFTER' | translate }}</span>
</div>
<div *ngIf="entry?.trigger?.__typename === 'Quest'">
  <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.QUEST_BEFORE' | translate }}</span>
  <span class="entry-name">{{ entry?.trigger?.description }}</span>
  <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.QUEST_AFTER' | translate }}</span>
</div>
<div *ngIf="entry?.trigger?.__typename === 'Activity'">
  <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.ACTIVITY_BEFORE' | translate }}</span>
  <span class="point-display">
    {{ pointReward }}{{ reward }}
    <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.POINTS' | translate }}</span>
  </span>
  <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.ACTIVITY_AFTER' | translate }}</span>
  <span class="point-display">
    <ng-container *ngIf="!!hourDuration">
      {{ hourDuration }}
      <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.HOURS' | translate }}</span>
    </ng-container>
    <ng-container *ngIf="!!minuteDuration">
      {{ minuteDuration }}
      <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.MINUTES' | translate }}</span>
    </ng-container>
  </span>
  <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.ACTIVITY_AFTER_TIME' | translate }}</span>
  <br />
  <br />
  <div class="entry-name">{{ entry?.trigger?.description }}</div>
</div>
<div *ngIf="entry?.trigger?.__typename === 'Badge'">
  <app-badge [badge]="badge" [showDescription]="true"></app-badge>
</div>
<div *ngIf="entry?.trigger?.__typename === 'TeamPlanning'">
  <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.PLANNING_BEFORE' | translate }}</span>
  <span class="entry-name">{{ entry?.trigger?.description }}</span>
  <br />
  <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.PLANNING_WHEN' | translate }}</span>
  <span class="entry-name">{{ entry?.trigger?.appointment | date: 'd.MM.yy, HH:MM' }}</span>
  <br />
  <span>{{ 'TIMELINE.ITEMS.CONTENT_TEXTS.PLANNING_AFTER' | translate }}</span>
</div>
