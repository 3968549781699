import {GameTenantKind} from './../../../../__generated__/globalTypes';
import {Apollo} from 'apollo-angular';
import {Injectable} from '@angular/core';
import {ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegmentGroup} from '@angular/router';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';

import {catchError, first, map, shareReplay, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {My, My_my, My_my_team_members} from './__generated__/My';
import {TeamMembershipKind} from '../../../../__generated__/globalTypes';
import {RewardUnlockedService} from '../reward-unlocked-service/reward-unlocked.service';
import {LevelUpService} from '../level-up-service/level-up.service';
import {SubscribedComponent} from '../../shared/misc/SubscribedComponent';
import {MatomoTracker} from 'ngx-matomo';
import {environment} from '../../../environments/environment';
import {MatomoHelperService} from '../matomo-helper.service';
import {RerouteOnLoginService} from '../reroute-on-login-service/reroute-on-login.service';
import {login, logout, myQuery} from './authentication.service.gql';
import { LoginVariables} from '../../modules/logged-out/components/login/__generated__/Login';
import { Login } from './__generated__/Login';

export enum Permission {
  TeamOwner,
  TeamAdmin,
  None,
}

@Injectable({providedIn: 'root'})
export class AuthenticationService extends SubscribedComponent {
  public initialized = false;
  public user: Observable<My_my | null>;
  private isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public isLoggedIn = this.isLoggedInSubject.asObservable();
  public gameTenantKind: GameTenantKind | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apollo: Apollo,
    rewardUnlockedService: RewardUnlockedService,
    levelUpService: LevelUpService,
    private matomoTracker: MatomoTracker,
    private matomo: MatomoHelperService,
    private rerouteService: RerouteOnLoginService,
  ) {
    super();
    this.user = this.isLoggedIn.pipe(
      switchMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return of(null);
        }

        return this.apollo.watchQuery<My>({query: myQuery}).valueChanges.pipe(
          map((user) => {
            this.initialized = true;
            return user.data.my;
          }),
          tap((user) => {
            if (user) {
              this.matomoTracker.setUserId(user.id);
              this.matomoTracker.setDocumentTitle('onya');
              this.matomoTracker.setCampaignNameKey(environment.releaseDate);
              this.gameTenantKind = user.gameTenant?.gameTenantKind;
              rewardUnlockedService.connect();
              levelUpService.connect();
            } else {
              rewardUnlockedService.disconnect();
              levelUpService.disconnect();
            }
          }),
        );
      }),
      shareReplay(1),
    );
    setTimeout(() => {
      // This is needed to trigger the initialized boolean
      this.user.pipe(takeUntil(this.onDestroy)).subscribe(() => {});
    });
  }

  public refreshUser() {
    this.isLoggedInSubject.next(this.isLoggedInSubject.value);
  }

  public hasPermission(permisson: Permission): Observable<boolean> {
    return this.user.pipe(map((u) => (u ? this.getPermissionUserId(u.id, (u.team && u.team.members) || []) === permisson : false)));
  }

  public hasPermissionInstant(permisson: Permission): boolean {
    let result = false;
    this.hasPermission(permisson)
      .pipe(take(1))
      .subscribe((p) => (result = p));
    return result;
  }

  private getPermissionUserId(userId: string, members: My_my_team_members[]): Permission {
    const member = members.find((m) => m.user.id === userId);
    if (member) {
      return this.mapTeamMembershipKindToPermissions(member.teamMembershipKind);
    }
    return Permission.None;
  }

  private mapTeamMembershipKindToPermissions(teamMembershipKind: TeamMembershipKind): Permission {
    switch (teamMembershipKind) {
      case TeamMembershipKind.Administrator:
        return Permission.TeamAdmin;
      case TeamMembershipKind.Member:
        return Permission.None;
      case TeamMembershipKind.Owner:
        return Permission.TeamOwner;
    }
  }

  public logout() {
    this.apollo
      .mutate<null, null>({
        mutation: logout,
      })
      .pipe(take(1))
      .subscribe(() => {
        if (!this.isAlreadyOnAuthRoute()) {
          this.router.navigate(['auth/login']);
        }
        this.isLoggedInSubject.next(false);
        if (this.initialized) {
          window.location.reload();
        }
      });
  }

  private isAlreadyOnAuthRoute(): boolean {
    const tree = this.router.parseUrl(this.router.url);
    const segmentGroup: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    if (!segmentGroup || !segmentGroup.segments) {
      return false;
    }
    return segmentGroup.segments.some((s) => s.path === 'auth');
  }

  public offlineLogout() {
    this.isLoggedInSubject.next(false);
  }

  public offlineLogin() {
    this.isLoggedInSubject.next(true);
  }

  public login(userName: string, password: string) {
    this.matomo.logSubmitForm('login');
    this.apollo
      .mutate<Login, LoginVariables>({
        mutation: login,
        variables: {
          loginName: userName,
          password: password,
        },
      })
      .pipe(first(), catchError((err)=> {
        this.isLoggedInSubject.next(false)
        return throwError(()=> err);
      } ))
      .subscribe((res) => {
        if (!res.errors) {
          this.offlineLogin();
        }
        if(!res.data?.login?.emailIsVerified){
          this.logout();
          this.router.navigate(['auth/verification']);
          this.offlineLogin();
          return;
        }
        this.isLoggedIn.pipe(first()).subscribe(() => {
          this.router.navigate([this.rerouteService.loadUrlSnapshot()]);
        });
      });
  }
}
