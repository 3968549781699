import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Directive() // tslint:disable-next-line
export class SubscribedComponent implements OnDestroy {
  protected onDestroy = new Subject();

  public ngOnDestroy(): void {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
