import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Entry} from '../../../modules/my-onya/components/timeline/timeline.component';

@Component({
  selector: 'app-timeline-display',
  templateUrl: './timeline-display.component.html',
  styleUrls: ['./timeline-display.component.scss'],
})
export class TimelineDisplayComponent implements OnChanges {
  @Input()
  public entries!: Entry[];

  public showYearForEntry: {
    [key: number]: boolean;
  } = {};

  constructor() {}

  ngOnChanges() {
    if (!this.entries) {
      this.entries = [];
    }

    for (let i = 0; i < this.entries.length; i++) {
      this.showYearForEntry[i] =
        i === 0 || new Date(this.entries[i - 1].createdAt).getFullYear() !== new Date(this.entries[i].createdAt).getFullYear();
    }
  }
}
