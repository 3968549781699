import { Component, Injector, forwardRef } from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable, map, switchMap } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { AppControlValueAccessorDirective } from '../AppControlValueAccessor';

import { GetMemberships, GetMemberships_my_team_members } from '../../../../../modules/my-team/components/memberships/__generated__/GetMemberships';
import { getMemberships } from '../../../../../modules/my-team/components/memberships/memberships.component';

import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';

type VisibilityOption = 'TeamMembers' | 'SelectedMembers' | 'OnlyMe';

@Component({
  selector: 'app-activity-visibility-picker',
  templateUrl: './activity-visibility-picker.component.html',
  styleUrls: ['./activity-visibility-picker.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ActivityVisibilityPickerComponent), multi: true}],
})
export class ActivityVisibilityPickerComponent extends AppControlValueAccessorDirective {

  visibilityOptions: {label: string, value: VisibilityOption}[] = [
    { label: 'ACTIVITY_VISIBILITY.TEAM_MEMBERS', value: 'TeamMembers' },
    { label: 'ACTIVITY_VISIBILITY.SELECTED_MEMBERS', value: 'SelectedMembers' },
    { label: 'ACTIVITY_VISIBILITY.ONLY_ME', value: 'OnlyMe' },
  ];
  
  selectedVisibility: VisibilityOption = 'TeamMembers';
  
  members$!: Observable<GetMemberships_my_team_members[] | null>;
  
  selectedMembers: string[] = [];
  
  readonly currentUser$ = this.authenticationService.user;
  
  constructor(
    protected injector: Injector,
    private apollo: Apollo,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.members$ = this.apollo
    .watchQuery<GetMemberships>({
      query: getMemberships,
    })
    .valueChanges.pipe(
      map((res) => res.data.my && res.data.my.team && res.data.my.team.members),
      switchMap((members)=> {
        return this.currentUser$.pipe(map((user)=> members?.filter(member => member.user.id !== user?.id) || []))
      })
    );
  }

  writeValue(obj: { visibility?: VisibilityOption, members?: string[] }): void {    
    this.selectedVisibility = obj?.visibility || 'TeamMembers';
    this.selectedMembers = obj?.members || [];

    setTimeout(() =>{
      this.notifyOnChange({ visibility: this.selectedVisibility, members: this.selectedMembers });
    })
  }
 
  onVisibilityChange(): void {
    if (this.selectedVisibility === 'SelectedMembers') {
      
    } else {
      this.selectedMembers = [];
    }
    
    this.notifyOnChange({ visibility: this.selectedVisibility, members: this.selectedMembers })
  }
  
  onMemberCheckboxChange(ev: any, member: GetMemberships_my_team_members){    
    if(ev.checked){
      if (!this.selectedMembers.some(existingMember => existingMember === member.user.id)) {
        this.selectedMembers = [...this.selectedMembers, member.user.id];
      }
    } else {
      this.selectedMembers = this.selectedMembers.filter((existingMember)=> existingMember !== member.user.id);
    }
        
    this.notifyOnChange({ visibility: this.selectedVisibility, members: this.selectedMembers });
  }
  
  isSelected(member: GetMemberships_my_team_members): boolean {
    return this.selectedMembers.some((existingMember) => existingMember === member.user.id);
  }
  
}
