import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {DatepickerComponent} from './components/datepicker/datepicker.component';
import {EmailAddressInputComponent} from './components/email-address-input/email-address-input.component';
import {NumberInputComponent} from './components/number-input/number-input.component';
import {RadioComponent} from './components/radio/radio.component';
import {TelephoneInputComponent} from './components/telephone-input/telephone-input.component';
import {TextInputComponent} from './components/text-input-cva/text-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PasswordInputComponent} from './components/password-input/password-input.component';
import {NewPasswordInputComponent} from './components/new-password-input/new-password-input.component';
import {TranslateModule} from '@ngx-translate/core';
import {ChipListComponent} from './components/chip-list/chip-list.component';
import {MaterialModule} from '../material/material.module';
import {LongTextInputComponent} from './components/long-text-input/long-text-input.component';
import {IconsModule} from '../../icons/icons.module';
import {WellbeingTypePickerComponent} from './components/wellbeing-type-picker/wellbeing-type-picker.component';
import {HashtagPickerComponent} from './components/hashtag-picker/hashtag-picker.component';
import {InfoButtonComponent} from './components/info-button/info-button.component';
import {WellbeingImprovementPickerComponent} from './components/wellbeing-improvement-picker/wellbeing-improvement-picker.component';
import {DirectivesModule} from '../../directives/directives.module';
import {AtomsModule} from '../atoms/atoms.module';
import { ActivityVisibilityPickerComponent } from './components/activity-visibility-picker/activity-visibility-picker.component';

const components = [
  CheckboxComponent,
  DatepickerComponent,
  EmailAddressInputComponent,
  NumberInputComponent,
  RadioComponent,
  TelephoneInputComponent,
  TextInputComponent,
  PasswordInputComponent,
  NewPasswordInputComponent,
  ChipListComponent,
  LongTextInputComponent,
  WellbeingTypePickerComponent,
  HashtagPickerComponent,
  InfoButtonComponent,
  WellbeingImprovementPickerComponent,
  ActivityVisibilityPickerComponent
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, FormsModule, TranslateModule, IconsModule, DirectivesModule, AtomsModule],
  declarations: [...components, NewPasswordInputComponent],

  exports: [...components, FormsModule, ReactiveFormsModule],
})
export class FormElementsModule {}
