import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-h-c-f-footer',
  templateUrl: './h-c-f-footer.component.html',
  styleUrls: ['./h-c-f-footer.component.scss'],
})
export class HCFFooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
