import {Component, Input, OnInit} from '@angular/core';
import {getAllAvatarImages_allAvatarImages} from '../avatar-picker/__generated__/getAllAvatarImages';

@Component({
  selector: 'app-avatar-item',
  templateUrl: './avatar-item.component.html',
  styleUrls: ['./avatar-item.component.scss'],
})
export class AvatarItemComponent implements OnInit {
  
  @Input() avatar!: getAllAvatarImages_allAvatarImages;
  @Input() backgroundColor?: string;
  
  constructor() {}

  ngOnInit() {}
}
