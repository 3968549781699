import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DatepickerComponent} from '../datepicker/datepicker.component';

@Component({
  selector: 'app-radio-cva',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent extends AppControlValueAccessorDirective implements OnInit {
  @Input()
  radioChoices: any[] = [];

  selectedModelObject: any;

  @Input()
  displayPath = '';

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {}

  writeValue(_obj: any): void {}
}
