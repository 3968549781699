<mat-form-field>
  <div>
    <mat-chip-list #chipList>
      <mat-chip
        [disabled]="isDisabled"
        *ngFor="let member of pickedMembers | async; let i = index"
        [removable]="!isDisabled"
        (removed)="remove(i)"
        [selectable]="false"
      >
        <div class="image-name-wrap">
          <img *ngIf="member.profileImage; else UserHasNoProfileImage" [src]="member.profileImage.size320x320Link" class="profile-image" />
          <ng-template #UserHasNoProfileImage>
            <fa-icon class="user-icon" icon="user-circle"></fa-icon>
          </ng-template>
          <div class="name-wrap">
            {{ member.displayAlias }}
          </div>
          <fa-icon *ngIf="!isDisabled" matChipRemove icon="times"></fa-icon>
        </div>
      </mat-chip>
      <input
        #filter
        class="input"
        [placeholder]="'ACTIVITIES.WITH' | translate"
        matInput
        [formControl]="formCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />
    </mat-chip-list>
    <app-info-button *ngIf="!isDisabled" [tooltip]="tooltipTranslate | translate"></app-info-button>
  </div>
  <mat-autocomplete #auto (optionSelected)="selected($event)">
    <mat-option *ngFor="let available of availableMembers | async" [value]="available">
      <div class="name-icon-row">
        <div class="member-name-column">
          <div class="block-text-font">
            {{ available.displayAlias }}
          </div>
          <div class="display-alias-font">@{{ available.loginName }}</div>
        </div>
        <div class="image-name-wrap">
          <img
            *ngIf="available.profileImage; else UserHasNoProfileImage"
            [src]="available.profileImage.size320x320Link"
            class="profile-image"
          />
          <ng-template #UserHasNoProfileImage>
            <fa-icon class="user-icon" icon="user-circle"></fa-icon>
          </ng-template>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
