import {gql} from 'apollo-angular';

export const FilterActivitiesQuery = gql`
  query ProfileSearch($search: String!, $discriminator: ActivitySearchDiscriminatorKind!) {
    profileSearch(search: $search, discriminator: $discriminator) {
      id
      createdAt
      user {
        id
        profileImage {
          id
          size320x320Link
          __typename
        }
        displayAlias
        __typename
      }
      timelineInteractions {
        id
        __typename
        author {
            id
            displayAlias
            profileImage {
              id
              size320x320Link
              __typename
            }
            __typename
        }
      }
      __typename
      ... on TimelineActivityEntry {
        id
        trigger {
          id
          description
          fiveWaysToWellbeings
          reward
          duration
          with {
            id
            displayAlias
            loginName
            profileImage {
              id
              size320x320Link
            }
          }
          timelineImages {
            size800x800Link
            originalLink
          }
          hashTags {
            id
            text
          }
        }
      }
    }
  }
`;
