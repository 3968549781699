import {Injectable} from '@angular/core';
import {MatomoInjector, MatomoTracker} from 'ngx-matomo';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MatomoHelperService {
  constructor(
    private matomoInjector: MatomoInjector,
    private matomoTracker: MatomoTracker,
  ) {}
  
  private readonly MATOMO_INSTANCE_URL = '//analytics.mein-onya.de/';
  
  public initCookies() {
    this.matomoInjector.init(this.MATOMO_INSTANCE_URL, environment.matomoId);
  }
  
  public hasCookies() {
    return this.matomoTracker.hasCookies();
  }
  
  public async disableCookies(){
    const hasCookies = await this.hasCookies();
    
    if(hasCookies){
      this.matomoTracker.disableCookies();
      this.matomoTracker.deleteCookies();
    }
  }

  public logNavigation(sourceComponent: string, toUrl: string, additionalData?: any) {
    this.logOtherAction(sourceComponent, 'navigation', toUrl, additionalData);
  }

  public logStartForm(sourceComponent: string, additionalData?: any) {
    this.logOtherAction(sourceComponent, 'form', 'start', additionalData);
  }

  public logCancelForm(sourceComponent: string, additionalData?: any) {
    this.logOtherAction(sourceComponent, 'form', 'cancel', additionalData);
  }

  public logSubmitForm(sourceComponent: string, additionalData?: any) {
    this.logOtherAction(sourceComponent, 'form', 'submit', additionalData);
  }

  public logSuccessForm(sourceComponent: string, additionalData?: any) {
    this.logOtherAction(sourceComponent, 'form', 'success', additionalData);
  }

  public logFailForm(sourceComponent: string, additionalData?: any) {
    this.logOtherAction(sourceComponent, 'form', 'fail', additionalData);
  }

  public logDialogShown(sourceComponent: string, additionalData?: any) {
    this.logOtherAction(sourceComponent, 'dialog', 'shown', additionalData);
  }

  public async logOtherAction(sourceComponent: string, action: string, name: string, additionalData?: any) {
    const hasCookies = await this.hasCookies();
        
    if (environment.production && hasCookies) {
      this.matomoTracker.trackEvent(sourceComponent, action, name, additionalData);
    }
  }
}
