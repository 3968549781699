import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {MatomoHelperService} from '../../../../../services/matomo-helper.service';

export const pendingUploadErrorKey = 'uploading';
export const pendingUploadValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const formGroup = control as FormGroup;
  const files = formGroup.get('files') as FormArray;

  const err = {
    [pendingUploadErrorKey]: true,
  };

  return files.controls.some((c) => c.hasError('required') || c.value.complete !== FileReporterCompleteStatus.Success) ? err : null;
};

@Component({
  selector: 'app-images-form-group',
  templateUrl: './images-form-group.component.html',
  styleUrls: ['./images-form-group.component.scss'],
})
export class ImagesFormGroupComponent implements OnInit {
  @ViewChild('File') filesInput: any;

  constructor(private fb: FormBuilder, public controlContainer: ControlContainer, private matomo: MatomoHelperService) {}

  ngOnInit(): void {
    const group = this.controlContainer.control as FormGroup;
    group.setValidators(pendingUploadValidator);
    group.setControl('files', this.fb.array([]));
  }

  addFiles() {
    this.filesInput.nativeElement.click();
  }

  // FILES
  get filesForm(): FormArray {
    // We know that the control of the controlContainer must be a `FormGroup`.
    // And has a `FormArray` named `files` which is created in `ngOnInit`
    return (this.controlContainer.control as FormGroup).get('files') as FormArray;
  }

  private createFileCtrl(file: FileReporter): FormControl {
    return this.fb.control(file, Validators.required);
  }

  private addFileCtrl(file: {fileName: string; url: string}): void {
    this.filesForm.push(this.createFileCtrl({...file, complete: FileReporterCompleteStatus.Pending, progress: 0}));
  }

  public removeFileCtrl(i: number): void {
    this.filesForm.removeAt(i);
  }

  onFileAdded(event: any) {
    this.matomo.logStartForm('images-group', 'file uploade started');
    if (!event.target.files || !event.target.files.length) {
      return;
    }

    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const url = window.URL.createObjectURL(file);

      this.addFileCtrl({
        url,
        fileName: file.name,
      });
    }
  }
}

export interface FileReporter {
  fileName: string;
  // Blob Object Url
  url: string;
  // Upload progress, does not mean we have a response yet
  progress: number;
  // Response status
  complete: FileReporterCompleteStatus;
  // Response id
  id?: string;
  
  color?: string;
}

export enum FileReporterCompleteStatus {
  NotInitated = 'NotInitiated',
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
}
