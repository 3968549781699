<div class="icon">
  <fa-icon icon="thumbs-up" size="4x"></fa-icon>
</div>

<div class="text">
  <div class="title-font title">
    {{ 'LEVEL_UP_DIALOG.TITLE' | translate }}
  </div>
  <div class="accent-font points">{{ data.name }}</div>
  <div class="block-text-font" *ngIf="data.unlocksQuests.length > 0">
    {{ 'LEVEL_UP_DIALOG.NEW_QUESTS' | translate }}
  </div>
  <ng-container *ngIf="data.infotainmentRewards.length > 0">
    <div class="title-font title">{{ 'LEVEL_UP_DIALOG.TITLE_REWARD' | translate }}</div>
    <app-reward *ngFor="let i of data.infotainmentRewards" [reward]="i"></app-reward>
  </ng-container>
  <app-raised-button (onClick)="closeDialog()" cdkFocusInitial>
    {{ 'LEVEL_UP_DIALOG.CLOSE' | translate }}
  </app-raised-button>
</div>
