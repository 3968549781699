import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {MustBeLoggedInGuard} from './guards/must-be-logged-in.guard';
import {MustBeLoggedOutGuard} from './guards/must-be-logged-out.guard';
import {Layouts} from './app.component';

const routes: Routes = [
  {path: '', redirectTo: '/activity/new', pathMatch: 'full', data: {layout: Layouts.LoggedIn, title: 'TITLES.NEW'}},
  {
    path: 'activity',
    loadChildren: () => import('./modules/activity/activity.module').then((m) => m.ActivityModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.NEW'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'my-onya',
    loadChildren: () => import('./modules/my-onya/my-onya.module').then((m) => m.MyOnyaModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.MY_ONYA'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'my-team',
    loadChildren: () => import('./modules/my-team/my-team.module').then((m) => m.MyTeamModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.MY_TEAM'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'my-goals',
    loadChildren: () => import('./modules/my-goals/my-goals.module').then((m) => m.MyGoalsModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.MY_GOALS'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'adventures',
    loadChildren: () => import('./modules/adventures/adventures.module').then((m) => m.AdventuresModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.ADVENTURES'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'leaderboards',
    loadChildren: () => import('./modules/leaderboards/leaderboards.module').then((m) => m.LeaderboardsModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.LEADERBOARDS'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.SETTINGS'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then((m) => m.FaqModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.FAQ'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'about',
    loadChildren: () => import('./modules/about/about.module').then((m) => m.AboutModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.ABOUT'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/logged-out/logged-out.module').then((m) => m.LoggedOutModule),
    data: {layout: Layouts.LoggedOut},
  },
  {
    path: 'achievements',
    loadChildren: () => import('./modules/achievements/achievements.module').then((m) => m.AchievementsModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.ACHIEVEMENTS'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'timelineItem',
    loadChildren: () => import('./modules/comments/comments.module').then((m) => m.CommentsModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.MY_ONYA'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notifications/notifications.module').then((m) => m.NotificationsModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.IMPORTANT'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'team',
    loadChildren: () => import('./modules/other-team/other-team.module').then((m) => m.OtherTeamModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.OTHER_TEAM'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/other-user/other-user.module').then((m) => m.OtherUserModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.OTHER_USER'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'chats',
    loadChildren: () => import('./modules/chats/chats.module').then((m) => m.ChatsModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.CHATS'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then((m) => m.SearchModule),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.SEARCH'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: 'my-notifications',
    loadChildren: () => import('./modules/my-notifications/my-notifications.module').then((m) => m.MyNotificationsModule ),
    data: {layout: Layouts.LoggedIn, title: 'TITLES.IMPORTANT'},
    canActivate: [MustBeLoggedInGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, {enableTracing: false, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
