import {Directive, EventEmitter, HostListener, Input, OnDestroy, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent, ConfirmDialogData} from '../../components/confirm-click/confirm-dialog/confirm-dialog.component';
import {Subject} from 'rxjs';
import {takeUntil, filter, tap, map} from 'rxjs/operators';
import {MatomoHelperService} from '../../../services/matomo-helper.service';

@Directive({
  selector: '[confirmClick]', // tslint:disable-line:directive-selector
})
export class ConfirmClickDirective implements OnDestroy {
  @Output() public confirmClickHandler: EventEmitter<boolean> = new EventEmitter();

  /**
   * The text of this ngx-translate key will be rendered as the title of the opened confirm dialog.
   * this defaults to the ngx-translate key 'CONFIRM_DIALOG.TITLE' if not set.
   */
  @Input() public titleTranslateKey: string = 'CONFIRM_DIALOG.TITLE';

  /**
   * The text of this ngx-translate key will be rendered in the textbox of the opened confirm dialog.
   * This defaults to an empty string if not set. Therefor only the title will be redered then.
   */
  @Input() public bodyTranslateKey: string = '';

  private onDestroy = new Subject();

  private readonly componentTrackingIdentifier = 'confirm-dialog';

  constructor(public dialog: MatDialog, private matomo: MatomoHelperService) {}

  @HostListener('click')
  public openDialog() {
    this.matomo.logDialogShown(this.componentTrackingIdentifier);

    const data: ConfirmDialogData = {
      titleTextKey: this.titleTranslateKey,
      bodyTextKey: this.bodyTranslateKey,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: data,
    });

    dialogRef
      .afterClosed()
      .pipe(
        map((res: boolean) => {
          if (res) {
            this.matomo.logSubmitForm(this.componentTrackingIdentifier);
          } else {
            this.matomo.logCancelForm(this.componentTrackingIdentifier);
          }
          return res;
        }),
        filter((res) => res),
        takeUntil(this.onDestroy),
      )
      .subscribe((result: boolean) => {
        this.confirmClickHandler.emit(result);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
