<form>
  <div class="row">
    <app-password-input
      [formControl]="passwordFormControl"
      [errorMapper]="[
        {error: 'required', message: 'SIGN_UP.PASSWORD_LENGTH_HINT' | translate},
        {error: 'minlength', message: 'SIGN_UP.PASSWORD_LENGTH_HINT' | translate},
        {error: 'pattern', message: 'REGISTRATION.PASSWORD-HINT-CHARS' | translate}
      ]"
    >
      <div [matTooltip]="('SIGN_UP.PASSWORD_LENGTH_HINT' | translate) + ' ' + ('REGISTRATION.PASSWORD-HINT-CHARS' | translate)">
        <i class="far fa-info-circle"></i>
      </div>
    </app-password-input>
  </div>
  <br />
  <div id="progressbar">
    <div></div>
  </div>
  <div class="strength-meter">
    <mat-progress-bar
      mode="determinate"
      [value]="currentPasswordStrength"
      [ngClass]="{
        red: currentPasswordStrength < 25,
        orange: currentPasswordStrength > 25 && currentPasswordStrength < 75,
        green: currentPasswordStrength > 75
      }"
    ></mat-progress-bar>
    <div class="bar-description">
      <div>Passwortstärke</div>
    </div>
  </div>
</form>
