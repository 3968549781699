<div (click)="openReward()" class="host" [matTooltip]="showDescription ? '' : reward?.description">
  <div class="reward-name-column">
    <div [ngClass]="rewardClass" class="icon-wrap">
      <fa-icon icon="trophy" class="icon" size="4x"></fa-icon>
    </div>
    <fa-icon icon="file-download" class="file-download"></fa-icon>
  </div>
  <div class="title block-text-font-small">{{ reward?.name }}</div>
  <div *ngIf="showDescription" [ngClass]="default" class="title block-text-font-small">{{ reward?.description }}</div>
</div>
