import { Component, Injector, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { SubscribedComponent } from 'src/app/shared/misc/SubscribedComponent';
import { AppControlValueAccessorDirective } from 'src/app/shared/modules/form-elements/components/AppControlValueAccessor';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ColorPickerComponent), multi: true}],
})
export class ColorPickerComponent extends AppControlValueAccessorDirective implements SubscribedComponent {

  @Input() colorOptions: {label: string, value: string}[] = [
    { label: 'RED', value: '#E3526C' },
    { label: 'ORANGE', value: '#EE7F49' },
    { label: 'YELLOW', value: '#FFB257' },
    { label: 'GREEN', value: '#7FB27E' },
    { label: 'TEAL', value: '#53BFC6' },
    { label: 'LIGHT_BLUE', value: '#69B8F5' },
    { label: 'DARK_BLUE', value: '#5A74D1' },
    { label: 'PURPLE', value: '#694ECB' },
    { label: 'PLUM', value: '#AD4DDC' },
    { label: 'PINK', value: '#F863D1' },
    { label: 'ROSE', value: '#ED479C' },
    { label: 'GRAY', value: '#84A1C6' },
  ];
  
  public selectedColor!: string;

  constructor(protected injector: Injector, public authenticationService: AuthenticationService) {
    super();

    authenticationService.user.pipe(takeUntil(this.onDestroy)).subscribe((user) => {
      if (user && user.visibility) {
        this.selectedColor = user.profileBackgroundColor || '#84A1C6';
      } else {
        this.selectedColor = '#84A1C6';
      }
    });
  }
  
  public selected() {
    this.notifyOnChange(this.selectedColor);
  }

  writeValue(color: string): void {
    this.selectedColor = color;
  }
  
  getSelectedColorLabel(value: string){
    return this.colorOptions.find((option)=> option.value === value)?.label
  }

}
