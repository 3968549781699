<div
  class="container"
  [ngClass]="gameTenantKind === 'Company' ? 'company-bg' : 'school-bg'"
  [routerLink]="routerLink"
  routerLinkActive="active accent-font"
>
  <app-icon-important></app-icon-important>
  <a>
    <ng-content></ng-content>
  </a>
</div>
