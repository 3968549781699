import {Apollo} from 'apollo-angular';
import {ApolloQueryResult} from '@apollo/client/core';
import {Component, OnInit} from '@angular/core';
import {My_my_profileImage} from '../../../../services/authenticationService/__generated__/My';
import {MatDialogRef} from '@angular/material/dialog';
import {Observable, of} from 'rxjs';

import {getAllAvatarImagesQuery} from './avatar-picker.component.gql';
import {getAllAvatarImages, getAllAvatarImages_allAvatarImages} from './__generated__/getAllAvatarImages';
import {map, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-avatar-picker',
  templateUrl: './avatar-picker.component.html',
  styleUrls: ['./avatar-picker.component.scss'],
})
export class AvatarPickerComponent implements OnInit {
  availableAvatars: Observable<getAllAvatarImages_allAvatarImages[]>;
  // availableAvatars: Observable<{avatar: getAllAvatarImages_allAvatarImages, color: string}[]>;
  
  selectedAvatar?: { avatar?: getAllAvatarImages_allAvatarImages, color?: string };
  
  colors = [
    '#E3526C',
    '#EE7F49',
    '#FFB257',
    '#7FB27E',
    '#53BFC6',
    '#69B8F5',
    '#5A74D1',
    '#694ECB',
    '#AD4DDC',
    '#F863D1',
    '#ED479C',
    '#84A1C6',
  ]

  constructor(public dialogRef: MatDialogRef<AvatarPickerComponent>, public apollo: Apollo) {
    this.availableAvatars = apollo
      .query<getAllAvatarImages>({
        query: getAllAvatarImagesQuery,
      })
      .pipe(
        map((result: ApolloQueryResult<getAllAvatarImages>) => {
          
          this.selectedAvatar = { avatar: result.data.allAvatarImages[0], color: this.colors[0] }
          
          return result.data.allAvatarImages;
        }),
      );
  }

  ngOnInit() {}
  
  selectColor(color: string){
    this.selectedAvatar = {...this.selectedAvatar, color }
  }
}
