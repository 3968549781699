import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-ios-install-hint',
  templateUrl: './ios-install-hint.component.html',
  styleUrls: ['./ios-install-hint.component.scss'],
})
export class IosInstallHintComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<IosInstallHintComponent>) {}

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close();
  }
}
