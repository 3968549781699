import {Injectable, TemplateRef} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {SubscribedComponent} from '../../../shared/misc/SubscribedComponent';
import {filter, pairwise, takeUntil} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService extends SubscribedComponent {
  private showSubject: Subject<TemplateRef<any> | null> = new Subject<TemplateRef<any> | null>();
  public templateChanged: Observable<TemplateRef<any> | null> = this.showSubject.asObservable();

  constructor(private router: Router) {
    super();
    this.router.events
      .pipe(
        filter((x) => x instanceof NavigationEnd), // Check only NavigationEnd events for their URL
        pairwise(), // Alsways get the last navigationURL and the current NavigationURL
        filter((x) => {
          const events = x as [NavigationEnd, NavigationEnd];
          // If the first element of the URL of the last and current NavigationURL is different, the main route was changed and therefor
          // the Portal of the toolbar shall be reset
          return events[0].url.split('/')[1] !== events[1].url.split('/')[1];
        }),
        takeUntil(this.onDestroy),
      )
      .subscribe(() => {
        // Reset the toolbars portal by sending NULL
        this.showSubject.next(null);
      });
  }

  show(template: TemplateRef<any>) {
    this.showSubject.next(template);
  }
}
