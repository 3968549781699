import {Directive, Input} from '@angular/core';

@Directive()
export class AbstractNavButtonDirective {
  @Input()
  routerLink!: string;

  @Input()
  disabled: boolean = false;

  constructor() {}
}
