import {NgModule} from '@angular/core';
import {AtomsModule} from './modules/atoms/atoms.module';
import {FormElementsModule} from './modules/form-elements/form-elements.module';
import {MaterialModule} from './modules/material/material.module';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {NoTeamMenuComponent} from './components/no-team-menu/no-team-menu.component';
import {FramesModule} from './modules/frames/frames.module';
import {TranslateModule} from '@ngx-translate/core';
import {IconsModule} from './icons/icons.module';
import {ConfirmDialogComponent} from './components/confirm-click/confirm-dialog/confirm-dialog.component';
import {TimelineDisplayComponent} from './components/timeline-display/timeline-display.component';
import {TimelineItemComponent} from './components/timeline-item/timeline-item.component';
import {CommonModule} from '@angular/common';
import {FiveWaysListBorderlessComponent} from './components/five-ways-list-borderless/five-ways-list-borderless.component';
import {BadgeComponent} from './components/badge/badge.component';
import {RouterModule} from '@angular/router';
import {ImagePopupComponent} from './components/timeline-item/image-popup/image-popup.component';
import {RewardComponent} from './components/reward/reward.component';
import {TeamMemberPickerComponent} from './components/team-member-picker/team-member-picker.component';
import {PickedTeamMemberListComponent} from './components/picked-team-member-list/picked-team-member-list.component';
import {TimelineItemHeaderRowComponent} from './components/timeline-item/timeline-item-header-row/timeline-item-header-row.component';
import {TimelineItemMainContentComponent} from './components/timeline-item/timeline-item-main-content/timeline-item-main-content.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ImageCropperFormControlComponent} from './components/image-cropper-form-control/image-cropper-form-control.component';
import {PendingInvitationsComponent} from './components/pending-invitations/pending-invitations.component';
import {AvatarPickerComponent} from './components/image-cropper-form-control/avatar-picker/avatar-picker.component';
import {AvatarItemComponent} from './components/image-cropper-form-control/avatar-item/avatar-item.component';
import {LevelAndPointsDisplayComponent} from './components/level-and-points-display/level-and-points-display.component';
import {TeamViewComponent} from './components/team-view/team-view.component';
import {DirectivesModule} from './directives/directives.module';
import {UserViewComponent} from './components/user-view/user-view.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {ToolbarExtraButtonDirective} from './directives/toolbar-extra-button.directive';
import {VideoHelpComponent} from './components/video-help/video-help.component';
import {VideoHelpDialogComponent} from './components/video-help/video-help-dialog/video-help-dialog.component';
import {VideoHelpButtonComponent} from './components/video-help/video-help-button/video-help-button.component';

const comps = [
  NoTeamMenuComponent,
  ConfirmDialogComponent,
  TimelineDisplayComponent,
  TimelineItemComponent,
  BadgeComponent,
  RewardComponent,
  FiveWaysListBorderlessComponent,
  TeamMemberPickerComponent,
  PickedTeamMemberListComponent,
  ImagePopupComponent,
  TimelineItemHeaderRowComponent,
  TimelineItemMainContentComponent,
  ImageCropperFormControlComponent,
  PendingInvitationsComponent,
  LevelAndPointsDisplayComponent,
  TeamViewComponent,
  UserViewComponent,
  ToolbarExtraButtonDirective,
  VideoHelpComponent,
];

const pipes = [SafeUrlPipe, SafeHtmlPipe];

const modules = [
  MaterialModule,
  AtomsModule,
  FormElementsModule,
  IconsModule,
  FramesModule,
  TranslateModule,
  CommonModule,
  RouterModule,
  ImageCropperModule,
  DirectivesModule,
];

@NgModule({
  imports: [...modules],
  exports: [...modules, ...comps, ...pipes],
  declarations: [...comps, ...pipes, AvatarPickerComponent, AvatarItemComponent, VideoHelpDialogComponent, VideoHelpButtonComponent],
})
export class SharedModule {}
