import {Apollo} from 'apollo-angular';
import {ApolloQueryResult} from '@apollo/client/core';
import {Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {My_my} from '../../../services/authenticationService/__generated__/My';
import {filter, map, startWith} from 'rxjs/operators';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AppControlValueAccessorDirective} from '../../modules/form-elements/components/AppControlValueAccessor';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MyTimeline_my_timelineEntries_TimelineActivityEntry_trigger_with} from '../../../modules/my-onya/components/timeline/__generated__/MyTimeline';

import {teamMemberPickerQuery} from './team-member-picker.component.gql';
import {teamMemberPicker} from './__generated__/teamMemberPicker';

export type TeamMember = MyTimeline_my_timelineEntries_TimelineActivityEntry_trigger_with;

@Component({
  selector: 'app-team-member-picker',
  templateUrl: './team-member-picker.component.html',
  styleUrls: ['./team-member-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TeamMemberPickerComponent),
      multi: true,
    },
  ],
})
export class TeamMemberPickerComponent extends AppControlValueAccessorDirective implements OnInit {
  public formCtrl = new FormControl();

  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public availableMembers!: Observable<TeamMember[]>;
  public pickedMembers: BehaviorSubject<TeamMember[]> = new BehaviorSubject<TeamMember[]>([]);
  private allMembers!: Observable<TeamMember[]>;

  @ViewChild('filter')
  private filter!: ElementRef<HTMLInputElement>;

  @Input()
  public tooltipTranslate!: string;

  constructor(private apollo: Apollo, protected injector: Injector) {
    super();

    this.allMembers = this.apollo
      .watchQuery<teamMemberPicker>({
        query: teamMemberPickerQuery,
      } as any)
      .valueChanges.pipe(
        map((user: ApolloQueryResult<teamMemberPicker>) => {
          return (user as any).data.my;
        }),
        filter((usr): usr is My_my => !!usr && !!usr.team),
        map((usr) => {
          return usr
            .team!.members.map((member) => member.user)
            .filter((teamMember) => {
              return teamMember.id !== usr.id;
            });
        }),
      );
  }

  ngOnInit(): void {
    this.availableMembers = combineLatest([this.pickedMembers, this.formCtrl.valueChanges.pipe(startWith('')), this.allMembers]).pipe(
      map(([pickedMembers, filterString, allMembers]) => {
        if (!filterString || filterString.id) {
          filterString = '';
        }

        return allMembers.filter((member) => {
          if (!member.displayAlias || !member.loginName) {
            return false;
          }

          const search = filterString.toLowerCase().trim();
          const nameMatches = member.displayAlias.toLowerCase().indexOf(search) >= 0 || member.loginName.toLowerCase().indexOf(search) >= 0;
          return nameMatches && pickedMembers.indexOf(member) === -1;
        });
      }),
    );
    if (this.isDisabled) {
      this.formCtrl.disable();
    }
  }

  public remove(i: number) {
    this.pickedMembers.next(this.pickedMembers.value.filter((_, j) => j !== i));
  }

  public selected(event: MatAutocompleteSelectedEvent) {
    this.pickedMembers.next([...this.pickedMembers.value, event.option.value]);

    this.notifyOnChange(this.pickedMembers.value);
    this.formCtrl.patchValue('');
    this.filter.nativeElement.value = '';
  }

  writeValue(obj: TeamMember[] | null): void {
    if (obj) {
      this.pickedMembers.next(obj);
    } else {
      this.pickedMembers.next([]);
    }
  }
}
