<mat-form-field>
  <div>
    <mat-chip-list #chipList>
      <mat-chip
        [disabled]="isDisabled"
        *ngFor="let hashtag of selectedHashtags | async"
        [removable]="!isDisabled"
        (removed)="remove(hashtag)"
        [selectable]="false"
      >
        #{{ hashtag.text }}
        <fa-icon *ngIf="!isDisabled" matChipRemove icon="times"></fa-icon>
      </mat-chip>
      <input
        class="input"
        #hashtagInput
        type="text"
        [placeholder]="'ACTIVITIES.HASHTAGS' | translate"
        matInput
        [formControl]="formCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        (matChipInputTokenEnd)="add($event)"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />
    </mat-chip-list>
    <app-info-button *ngIf="!isDisabled" [tooltip]="'ACTIVITIES.HASHTAGS_TOOLTIP' | translate"></app-info-button>
  </div>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let hashtag of filteredAvailableHashtags | async" [value]="hashtag">#{{ hashtag.text }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
