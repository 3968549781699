import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authenticationService/authentication.service';
import {first, map} from 'rxjs/operators';
import {My_my} from '../services/authenticationService/__generated__/My';

@Injectable({
  providedIn: 'root',
})
export class MustBeLoggedInGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(
    // @ts-ignore
    next: ActivatedRouteSnapshot,
    // @ts-ignore
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isLoggedIn.pipe(
      first(),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(['/auth/login']);
        }
        return isLoggedIn;
      }),
    );
  }
}
