<div>
  <div class="field-container">
    <mat-form-field>
      <input
        matInput
        type="password"
        [(ngModel)]="innerValue"
        (input)="notifyOnChange(innerValue)"
        (focusin)="notifyOnTouch()"
        [placeholder]="elementName"
        [disabled]="isDisabled"
        [errorStateMatcher]="errorStateMatcher"
        #textinput
      />
    </mat-form-field>
    <ng-content></ng-content>
    <ng-container *ngFor="let err of errorMapper">
      <mat-error *ngIf="touched && errors && errors[err.error]">
        {{ err.message }}
      </mat-error>
    </ng-container>
  </div>
</div>
