<div class="section">
  <div class="header">
    <div><ng-content select="app-h-c-f-header-title"></ng-content></div>
    <div>
      <ng-content select="app-h-c-f-header-button"></ng-content>
    </div>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="footer">
    <ng-content select="app-h-c-f-footer"></ng-content>
  </div>
</div>
