import {Component, Input, OnInit} from '@angular/core';
import {TeamMember} from '../team-member-picker/team-member-picker.component';

@Component({
  selector: 'app-picked-team-member-list',
  templateUrl: './picked-team-member-list.component.html',
  styleUrls: ['./picked-team-member-list.component.scss'],
})
export class PickedTeamMemberListComponent implements OnInit {
  @Input()
  public pickedMembers!: TeamMember[];

  constructor() {}

  ngOnInit() {
    if (!this.pickedMembers) {
      this.pickedMembers = [];
    }
  }
}
