import {Component, forwardRef, Injector, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';
import {first} from 'rxjs/operators';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent extends AppControlValueAccessorDirective implements OnInit {
  public innerValue!: string;

  constructor(private transService: TranslateService, protected injector: Injector) {
    super();
  }

  ngOnInit() {
    if (!this.elementName) {
      this.transService
        .get('SIGN_UP.PASSWORD')
        .pipe(first())
        .subscribe((x) => {
          this.elementName = x;
        });
    }
  }

  writeValue(obj: any): void {
    this.innerValue = obj;
  }
}
