import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LevelUp_levelUp} from '../__generated__/LevelUp';

@Component({
  selector: 'app-level-up-dialog',
  templateUrl: './level-up-dialog.component.html',
  styleUrls: ['./level-up-dialog.component.scss'],
})
export class LevelUpDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: LevelUp_levelUp, public dialogRef: MatDialogRef<LevelUpDialogComponent>) {}

  public closeDialog() {
    this.dialogRef.close();
  }
}
