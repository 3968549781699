import {gql, Apollo} from 'apollo-angular';
import {Component, OnInit} from '@angular/core';

import {Observable} from 'rxjs';
import {GetMemberships, GetMemberships_my_team_members} from './__generated__/GetMemberships';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatomoHelperService} from '../../../../services/matomo-helper.service';

export const getMemberships = gql`
  query GetMemberships {
    my {
      id
      displayAlias
      team {
        id
        members {
          inboxId
          teamMembershipKind
          user {
            id
            displayAlias
            profileBackgroundColor
            profileImage {
              id
              size320x320Link
            }
          }
          joinedAt
        }
      }
    }
  }
`;

@Component({
  selector: 'app-memberships',
  styleUrls: ['./memberships.component.scss'],
  templateUrl: './memberships.component.html',
})
export class MembershipsComponent implements OnInit {
  public memberships!: Observable<GetMemberships_my_team_members[] | null>;

  constructor(private apollo: Apollo, private router: Router, private matomo: MatomoHelperService) {}

  ngOnInit() {
    this.memberships = this.apollo
      .watchQuery<GetMemberships>({
        query: getMemberships,
      })
      .valueChanges.pipe(map((res) => res.data.my && res.data.my.team && res.data.my.team.members));
  }
}
