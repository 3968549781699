import {NgModule} from '@angular/core';
import {MenuContentComponent} from './components/menu/menu-content/menu-content.component';
import {MenuComponent} from './components/menu/menu.component';
import {MaterialModule} from '../material/material.module';
import {CommonModule} from '@angular/common';
import {HCFComponent} from './components/header-content/h-c-f.component';
import {HCFHeaderButtonComponent} from './components/header-content/header-content-header-button/h-c-f-header-button.component';
import {HCFFooterComponent} from './components/header-content/header-content-footer/h-c-f-footer.component';
import {HCFHeaderTitleComponent} from './components/header-content/header-content-title/h-c-f-header-title.component';
import {SectionComponent} from './components/section/section.component';
import {EditableSectionHeaderComponent} from './components/section/components/editable-section-header/editable-section-header.component';
import {SectionHeaderComponent} from './components/section/components/section-header/section-header.component';
import {AtomsModule} from '../atoms/atoms.module';

const comps = [
  MenuComponent,
  MenuContentComponent,
  HCFComponent,
  HCFHeaderButtonComponent,
  HCFFooterComponent,
  HCFHeaderTitleComponent,
  SectionComponent,
  EditableSectionHeaderComponent,
  SectionHeaderComponent,
];

@NgModule({
  imports: [MaterialModule, CommonModule, AtomsModule],
  declarations: [comps],
  exports: [comps],
})
export class FramesModule {}
