<div class="horizontal w-100 flex-wrap">
  <form>
    <mat-form-field class="email-address">
      <input
        matInput
        (focusin)="notifyOnTouch()"
        [placeholder]="'GENERAL.EMAIL' | translate"
        [formControl]="emailFormControl"
        [errorStateMatcher]="errorStateMatcher"
      />
    </mat-form-field>
    <mat-error *ngIf="touched && (emailFormControl.hasError('email') || emailFormControl.hasError('required'))">
      {{ 'GENERAL.EMAIL-REQUIRED' | translate }}
    </mat-error>
  </form>
</div>
