import {Apollo, gql} from 'apollo-angular';
import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';
import {bufferTime, filter, map, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {AchievementDialogComponent} from './achievement-dialog/achievement-dialog.component';
import {BadgeFragment} from '../../shared/graph-ql/badge-fragment';
import {BadgeUnlocked} from './__generated__/BadgeUnlocked';

const badgeUnlockedSubscription = gql`
  subscription BadgeUnlocked {
    badgeUnlocked {
      ...BadgeFragment
    }
  }
  ${BadgeFragment}
`;

@Injectable({
  providedIn: 'root',
})
export class RewardUnlockedService {
  private initialized = false;
  private disconnectSubscription = new Subject<boolean>();

  constructor(private apollo: Apollo, private dialog: MatDialog) {}

  public connect(): void {
    if (this.initialized) {
      return;
    }

    this.apollo
      .subscribe<BadgeUnlocked>({
        query: badgeUnlockedSubscription,
      })
      .pipe(
        map((e) => (e && e.data ? e.data.badgeUnlocked : null)),
        bufferTime(1500),
        filter((e) => e.length > 0),
        takeUntil(this.disconnectSubscription),
      )
      .subscribe(
        (unlocks) => {
          this.dialog.open(AchievementDialogComponent, {
            maxHeight: '80%',
            data: unlocks,
          });
        },
        (err) => {
          this.initialized = false;
          return err;
        },
      );

    this.initialized = true;
  }

  public disconnect(): void {
    this.initialized = false;
    this.disconnectSubscription.next(true);
  }
}
