<div routerLink="/leaderboards/players" (click)="pointClick.emit()">
  <span class="data" [ngClass]="gameTenantKind === 'Company' ? 'company-bg' : 'school-bg'">
    {{ totalPoints }}
  </span>
  <span class="name">
    {{ 'MY_ONYA.POINTS' | translate }}
  </span>
</div>
<div (click)="levelClick.emit()" routerLink="/faq/levels">
  <span class="data" [ngClass]="gameTenantKind === 'Company' ? 'company-bg' : 'school-bg'">
    {{ currentLevelName }}
  </span>
  <span class="name">
    {{ 'MY_ONYA.LEVEL' | translate }}
  </span>
</div>
