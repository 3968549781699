import {Component, forwardRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {TextInputComponent} from '../text-input-cva/text-input.component';

@Component({
  selector: 'app-datepicker-cva',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent extends AppControlValueAccessorDirective implements OnInit {
  @Input()
  representedDate: Date = new Date();

  @Input()
  minDate?: Date;
  @Input()
  maxDate?: Date;

  @ViewChild('picker')
  private picker: any;
  open() {
    this.picker.open();
  }

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {}

  writeValue(obj: any): void {
    this.representedDate = obj;
  }

  valueChanges() {
    this.notifyOnChange(this.representedDate);
    this.notifyOnTouch();
  }
}
