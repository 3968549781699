<a (click)="closeDialog()">
  <fa-icon icon="times"></fa-icon>
</a>

<div class="title-font">
  {{ 'VIDEO_HELP.TITLE' | translate }}
</div>
<br />
<div class="player">
  <div [innerHtml]="yt_iframe_html"></div>
</div>
