import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-h-rule',
  templateUrl: './h-rule.component.html',
  styleUrls: ['./h-rule.component.scss'],
})
export class HRuleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
