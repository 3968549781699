import {Component, forwardRef, Injector} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-new-password-input',
  templateUrl: './new-password-input.component.html',
  styleUrls: ['./new-password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewPasswordInputComponent),
      multi: true,
    },
  ],
})
export class NewPasswordInputComponent extends AppControlValueAccessorDirective {
  public currentPasswordStrength: number = 0;

  passwordFormControl = new FormControl('', {
    validators: [Validators.required, Validators.minLength(8)],
    updateOn: 'change',
  });

  public innerValue!: string;

  constructor(protected injector: Injector) {
    super();

    this.passwordFormControl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((password: string) => {
      this.currentPasswordStrength = this.calculatePasswordStrength(password);

      if (this.passwordFormControl.valid) {
        this.notifyOnChange(password);
      } else {
        this.notifyOnChange(undefined);
      }
    });
  }

  public writeValue(obj: string): void {
    this.passwordFormControl.setValue(obj);
  }

  private calculatePasswordStrength(password: string): number {
    if (!password) {
      return 0;
    }

    const amounts = {
      lower: 0,
      upper: 0,
      number: 0,
      symbol: 0,
    };

    for (let i = 0; i < password.length; i++) {
      const currentChar = password.charAt(i).charCodeAt(0);

      if (currentChar >= 97 && currentChar <= 122) {
        amounts.lower++;
        continue;
      }

      if (currentChar >= 65 && currentChar <= 90) {
        amounts.upper++;
        continue;
      }

      if (currentChar >= 48 && currentChar <= 57) {
        amounts.number++;
        continue;
      }

      amounts.symbol++;
    }

    let strength = Object.values(amounts).reduce((previous, current) => {
      return previous + (current > 0 ? 1 : 0);
    }, 0);

    strength += Math.floor((password.length - 8) / 5);

    strength = Math.min(strength, 4);

    return strength === 4 ? 100 : strength * 27 - 17;
  }
}
