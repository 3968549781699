import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService, Permission} from '../../../services/authenticationService/authentication.service';

/*
  Example usage from a template after you bound the enum in the component:
  `*appHasPermission="[Permission.TeamOwner, Permission.TeamAdmin]"`

  The implementation is a logical OR between multiple permissions.
 */
@Directive({
  selector: '[appHasPermission]',
})
export class HasPermissionDirective {
  private permissions: Permission[] = [];
  private isHidden = true;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthenticationService,
  ) {}

  @Input('appHasPermission')
  set hasPermission(permissions: Permission[]) {
    this.permissions = permissions;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    return this.permissions.some((p) => this.authService.hasPermissionInstant(p));
  }
}
