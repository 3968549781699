import {AuthenticationService} from 'src/app/services/authenticationService/authentication.service';
import {GameTenantKind} from './../../../../../../../../__generated__/globalTypes';
import {Component, Input, OnInit} from '@angular/core';
import {AbstractButtonDirective} from '../abstract-button';

@Component({
  selector: 'app-raised-button',
  templateUrl: './raised-button.component.html',
  styleUrls: ['./raised-button.component.scss'],
})
export class RaisedButtonComponent extends AbstractButtonDirective implements OnInit {
  gameTenantKind: GameTenantKind | undefined;
  constructor(private authService: AuthenticationService) {
    super();
    this.gameTenantKind = authService.gameTenantKind;
  }

  ngOnInit() {}
}
