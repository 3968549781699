import {ErrorHandler, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../environments/environment';
import {first} from 'rxjs/operators';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  private defaultServiceContext: GceErrorServiceContext = {
    resourceType: 'frontend',
    service: 'onya-web-frontend',
    version: environment.releaseDate,
  };

  constructor(private httpClient: HttpClient) {}

  handleError(error: Error) {
    const errorToBePosted: GceErrorEvent = {
      eventTime: new Date().toISOString(),
      message: error.stack,
      serviceContext: this.defaultServiceContext,
    };
    // do something with the exception
    const params = new HttpParams().set('key', environment.errorReportingApiKey);
    if (error.message.indexOf('401') > -1) {
      return;
    }
    if (environment.production) {
      this.httpClient
        .post(environment.errorReportingUrl, errorToBePosted, {
          params: params,
        })
        .pipe(first())
        .subscribe();
    } else {
      console.log(error);
    }
  }
}

interface GceErrorServiceContext {
  service: string;
  version: string;
  resourceType: string;
}

interface GceErrorEvent {
  eventTime: string;
  serviceContext: GceErrorServiceContext;
  message: string | undefined;
}
