import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-nav-item-button',
  templateUrl: './nav-item-button.component.html',
  styleUrls: ['./nav-item-button.component.scss'],
})
export class NavItemButtonComponent implements OnInit {
  @Input()
  title!: string;

  @Input()
  public iconName!: string;

  constructor() {}

  ngOnInit() {}
}
