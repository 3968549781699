<div>
  <div>{{ elementName }}</div>
  <br />
  <mat-radio-group class="example-radio-group">
    <mat-radio-button class="example-radio-button" *ngFor="let item of radioChoices" [value]="item">
      {{ displayPath }}
      {{ displayPath !== '' ? item[displayPath] : item }}
    </mat-radio-button>
  </mat-radio-group>
</div>
