import {Component, forwardRef, Injector} from '@angular/core';
import {AppControlValueAccessorDirective} from '../../../../form-elements/components/AppControlValueAccessor';
import {Gender} from '../../../../../../../../__generated__/globalTypes';
import {AuthenticationService} from '../../../../../../services/authenticationService/authentication.service';
import {SubscribedComponent} from '../../../../../misc/SubscribedComponent';
import {takeUntil} from 'rxjs/operators';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-gender-picker',
  templateUrl: './gender-picker.component.html',
  styleUrls: ['./gender-picker.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GenderPickerComponent), multi: true}],
})
export class GenderPickerComponent extends AppControlValueAccessorDirective implements SubscribedComponent {
  public Gender = Gender;
  public Object = Object;
  public selectedGender!: Gender;

  constructor(protected injector: Injector, public authenticationService: AuthenticationService) {
    super();

    authenticationService.user.pipe(takeUntil(this.onDestroy)).subscribe((user) => {
      if (user && user.gender) {
        this.selectedGender = user.gender;
      } else {
        this.selectedGender = Gender.Unspecified;
      }
    });
  }

  public selected() {
    this.notifyOnChange(this.selectedGender);
  }

  writeValue(obj: Gender): void {
    this.selectedGender = obj;
  }
}
