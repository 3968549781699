import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BadgeFragment} from '../../../shared/graph-ql/__generated__/BadgeFragment';
import {myBadges_my_badges} from '../../../modules/achievements/components/badges-list/__generated__/myBadges';

@Component({
  selector: 'app-achievement-dialog',
  templateUrl: './achievement-dialog.component.html',
  styleUrls: ['./achievement-dialog.component.scss'],
})
export class AchievementDialogComponent {
  public badges: myBadges_my_badges[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: BadgeFragment[], public dialogRef: MatDialogRef<AchievementDialogComponent>) {
    this.badges = data.map((e) => ({__typename: 'UserBadge', isUnlocked: true, badge: e} as myBadges_my_badges));
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
