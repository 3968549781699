import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-h-c-f',
  templateUrl: './h-c-f.component.html',
  styleUrls: ['./h-c-f.component.scss'],
})
export class HCFComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
