import {Component, Input, OnInit} from '@angular/core';
import {GameTenantKind} from '__generated__/globalTypes';

@Component({
  selector: 'app-tab-bar-tab',
  templateUrl: './tab-bar-tab.component.html',
  styleUrls: ['./tab-bar-tab.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBarTabComponent implements OnInit {
  @Input() gameTenantKind: GameTenantKind | undefined;
  @Input()
  tabRouterLink!: string;

  @Input()
  interactionDisabled: boolean = false;

  constructor() {}

  ngOnInit() {}
}
