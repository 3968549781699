import {Component, OnInit} from '@angular/core';
import {AbstractButtonDirective} from '../abstract-button';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent extends AbstractButtonDirective implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {}
}
