import {gql} from 'apollo-angular';

export const BadgeFragment = gql`
  fragment BadgeFragment on Badge {
    id
    name
    description
    badgeCategory
    badgeType
    titelWeiblich
    titelNeutrum
    titelMaennlich
    iconName
    sortValue
    rarity
    percentage
    isSelected
    imagePath
  }
`;
