import {AfterViewInit, Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {ToolbarService} from '../../core/components/toolbar/toolbar.service';

@Directive({
  selector: '[appToolbarExtraButton]',
})
export class ToolbarExtraButtonDirective implements AfterViewInit {
  constructor(private barService: ToolbarService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.barService.show(this.templateRef);
    });
  }
}
