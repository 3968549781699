import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from './components/buttons/button/button.component';
import {IconButtonComponent} from './components/buttons/icon-button/icon-button.component';
import {NavButtonComponent} from './components/buttons/nav-button/nav-button.component';
import {NavRaisedButtonComponent} from './components/buttons/nav-raised-button/nav-raised-button.component';
import {RaisedButtonComponent} from './components/buttons/raised-button/raised-button.component';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../material/material.module';
import {TabBarComponent} from './components/tab-bar/tab-bar.component';
import {TabBarTabComponent} from './components/tab-bar/components/tab-bar-tab/tab-bar-tab.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {HRuleComponent} from './components/h-rule/h-rule.component';
import {TranslateModule} from '@ngx-translate/core';
import {GenderPickerComponent} from './components/selects/gender-picker/gender-picker.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {IconsModule} from '../../icons/icons.module';
import {ChipComponent} from './components/chip/chip.component';
import { VisibilityPickerComponent } from './components/selects/visibility-picker/visibility-picker.component';
import { ColorPickerComponent } from './components/selects/color-picker/color-picker.component';

const components = [
  ButtonComponent,
  IconButtonComponent,
  NavButtonComponent,
  NavRaisedButtonComponent,
  RaisedButtonComponent,
  TabBarComponent,
  TabBarTabComponent,
  SpinnerComponent,
  HRuleComponent,
  GenderPickerComponent,
  VisibilityPickerComponent,
  PaginationComponent,
  ChipComponent,
  ColorPickerComponent
];

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule, ReactiveFormsModule, TranslateModule, IconsModule],
  declarations: [...components],
  exports: [...components],
})
export class AtomsModule {}
