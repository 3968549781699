import {Apollo} from 'apollo-angular';
import {ApolloQueryResult} from '@apollo/client/core';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, merge, Observable, of, Subject} from 'rxjs';
import {OnyaNotification, OnyaNotificationType} from './notification.model';

import {catchError, distinctUntilChanged, filter, map, mergeMap, repeat, switchMap, takeUntil, tap} from 'rxjs/operators';
import {AuthenticationService} from '../../services/authenticationService/authentication.service';
import {SurveyKind} from '../../../../__generated__/globalTypes';
import {Router} from '@angular/router';
import {SubscribedComponent} from '../../shared/misc/SubscribedComponent';
import {Notifications} from './__generated__/Notifications';
import {notificationsQuery} from './notification.service.gql';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends SubscribedComponent {
  private notifications: BehaviorSubject<OnyaNotification<any>[]> = new BehaviorSubject([] as OnyaNotification<any>[]);
  public Notifications: Observable<OnyaNotification<any>[]> = this.notifications.asObservable();

  private refreshNotificationsSubject: Subject<boolean> = new Subject();

  constructor(private apollo: Apollo, private authService: AuthenticationService, private router: Router) {
    super();

    merge(this.authService.isLoggedIn, this.refreshNotificationsSubject)
      .pipe(
        filter((isLoggedIn) => isLoggedIn),
        distinctUntilChanged(),
        switchMap(
          () =>
            this.apollo.watchQuery<Notifications>({
              query: notificationsQuery,
              fetchPolicy: 'network-only',
            }).valueChanges,
        ),
        // To be able to remove catchError and repeat, apollo must be updated, because otherwise this obs completes on error (401)
        catchError(() => {
          return of(false);
        }),
        repeat(),
        takeUntil(this.onDestroy),
      )
      .subscribe((res) => {
        if (!res) {
          return;
        }
        const {
          data: {my},
        } = res as any as ApolloQueryResult<Notifications>;
        const emailIsVerified = my ? my.emailIsVerified : true;
        const nonTakenSurveys = my ? my.surveyNotifications : [];
        const news = my
          ? my.news.map((n) => ({
              Type: OnyaNotificationType.News,
              data: n,
            }))
          : [];

        // Initialize the new Notifications array
        let allNotifications: OnyaNotification<any>[] = [];

        // Collect all notifications regarding not taken surveys and push them to all notifications.
        // THE ORDER IS IMPORTANT!
        // the survery notifications must always come first!
        allNotifications = [
          ...nonTakenSurveys.map((s) => ({
            Type:
              s.survey.kind === SurveyKind.StartSurvey ? OnyaNotificationType.StartSurveyNotTaken : OnyaNotificationType.EndSurveyNotTaken,
            data: s,
          })),
        ];

        const oldNotificationCount: number = this.notifications.value.length;

        // THE ORDER IS IMPORTANT!
        // The email not verified notification must always come second!
        if (!emailIsVerified) {
          const emailNotVerifiedNotification: OnyaNotification<undefined> = {
            Type: OnyaNotificationType.EmailNotVerified,
            data: undefined,
          };
          allNotifications.push(emailNotVerifiedNotification);
        }

        // In the end append all news notifications
        allNotifications = [...allNotifications, ...news];

        this.notifications.next(allNotifications);
        // Check if there were notifications before and if they were removed. If so, navigate to new activity
        if (oldNotificationCount > 0 && allNotifications.length === 0) {
          this.router.navigate(['activity/new']);
        }
      });
  }

  public get HasNotifications(): Observable<boolean> {
    return this.Notifications.pipe(
      map((data: OnyaNotification<any>[]) => {
        return data.length > 0;
      }),
    );
  }

  // This method is needed because this service will be initialized in the root scope.
  // So after a registration we need to manually trigger the populating of the notifications.
  public refreshNotifications() {
    this.refreshNotificationsSubject.next(true);
  }
}
