import {gql} from 'apollo-angular';

export const login = gql`
  mutation Login($loginName: String!, $password: String!) {
    login(loginName: $loginName, password: $password) {
      id
      email
      gender
      visibility
      profileBackgroundColor
      loginName
      emailIsVerified
    }
  }
`;

export const myQuery = gql`
  query My {
    my {
      id
      visibility
      profileBackgroundColor
      email
      gender
      loginName
      description
      location
      emailIsVerified
      gameTenantId
      gameTenant {
        gameTenantKind
      }
      profileImage {
        id
        size320x320Link
      }
      displayAlias
      totalPoints
      currentLevel {
        id
        name
        minimumPointAmount
      }
      amountAcquiredBadges
      amountAcquiredOnyas
      amountCreatedActivities
      amountDoneQuests
      levelProgress {
        name
        progress
        nextLevelNeededPoints
      }
      team {
        id
        teamname
        displayAlias
        members {
          teamMembershipKind
          user {
            id
            loginName
            displayAlias
            profileImage {
              id
              size320x320Link
            }
          }
        }
      }
      badgeSelectedByUserId{ 
        badge{ 
          id
          badgeType, 
          rarity,
          iconName,
          name
          imagePath
        }
      }
    }
  }
`;


export const logout = gql`
  mutation Logout {
    logout
  }
`;
