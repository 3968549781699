import {gql} from 'apollo-angular';

export const GetTeamActivityQuery = gql`
  query TeamActivity {
    my {
      id
      team {
        id
        timelineEntries {
          id
          user {
            id
            displayAlias
            profileImage {
              id
              size320x320Link
            }
          }
          createdAt
          __typename
          timelineInteractions {
            id
            __typename
            author {
              id
              displayAlias
              profileImage {
                id
                size320x320Link
              }
            }
          }
          ... on TimelineActivityEntry {
            trigger {
              id
              description
              fiveWaysToWellbeings
              reward
              duration
              with {
                id
                displayAlias
                loginName
                profileImage {
                  id
                  size320x320Link
                }
              }
              timelineImages {
                size800x800Link
              }
              hashTags {
                id
                text
              }
            }
          }
          ... on TimelineBadgeEntry {
            trigger {
              id
              badgeCategory
              badgeType
              description
              iconName
              name
              titelMaennlich
              titelNeutrum
              titelWeiblich
            }
          }
          ... on TimelineLevelUpEntry {
            trigger {
              id
              description
              name
            }
          }
          ... on TimelinePlanningEntry {
            trigger {
              id
              description
              appointment
            }
          }
          ... on TimelineQuestEntry {
            trigger {
              id
              name
              description
              pointReward {
                id
                amount
              }
              fiveWaysToWellbeings
            }
          }
        }
      }
    }
  }
`;
