import {Component, ElementRef, forwardRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';

@Component({
  selector: 'app-long-text-input',
  templateUrl: './long-text-input.component.html',
  styleUrls: ['./long-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LongTextInputComponent),
      multi: true,
    },
  ],
})
export class LongTextInputComponent extends AppControlValueAccessorDirective implements OnInit {
  @Input()
  innerValue!: string;

  @Input()
  tooltip: string = '';

  @ViewChild('textinput')
  textinput!: ElementRef;

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {}

  writeValue(newVal: string): void {
    this.innerValue = newVal;
  }

  focus() {
    setTimeout(() => {
      this.textinput.nativeElement.focus();
    });
  }
}
