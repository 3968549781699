import {Component, Input, OnInit} from '@angular/core';
import {MyTimeline_my_timelineEntries} from '../../../../modules/my-onya/components/timeline/__generated__/MyTimeline';
import {myBadges_my_badges} from '../../../../modules/achievements/components/badges-list/__generated__/myBadges';

@Component({
  selector: 'app-timeline-item-main-content',
  templateUrl: './timeline-item-main-content.component.html',
  styleUrls: ['./timeline-item-main-content.component.scss'],
})
export class TimelineItemMainContentComponent implements OnInit {
  @Input() entry!: MyTimeline_my_timelineEntries;
  @Input() public badge?: myBadges_my_badges;
  @Input() public pointReward?: number;
  @Input() public reward?: number;
  @Input() public duration?: number;

  public minuteDuration?: number;
  public hourDuration?: number;

  constructor() {}

  ngOnInit() {
    if (this.duration) {
      this.minuteDuration = this.duration % 60;
      this.hourDuration = (this.duration - this.minuteDuration) / 60;
    }
  }
}
