import {Injectable} from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
import {filter, first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RerouteOnLoginService {
  private nextRouteOnLogin: string | undefined = undefined;

  constructor(private router: Router) {}

  public saveUrlSnapshot() {
    this.router.events
      .pipe(
        filter((x) => x instanceof RoutesRecognized),
        first(),
      )
      .subscribe((x) => {
        this.nextRouteOnLogin = (x as RoutesRecognized).urlAfterRedirects;
        if (this.nextRouteOnLogin && this.nextRouteOnLogin.indexOf('auth') > 0 && this.nextRouteOnLogin.indexOf('verification') < 0) {
          this.nextRouteOnLogin = undefined;
        }
      });
  }

  public loadUrlSnapshot(): string {
    return this.nextRouteOnLogin || '';
  }
}
