import {Component, Input, OnInit} from '@angular/core';
import {FiveWaysToWellbeing} from '../../../../../__generated__/globalTypes';

@Component({
  selector: 'app-five-ways-list-borderless',
  templateUrl: './five-ways-list-borderless.component.html',
  styleUrls: ['./five-ways-list-borderless.component.scss'],
})
export class FiveWaysListBorderlessComponent implements OnInit {
  @Input()
  public fiveWays!: FiveWaysToWellbeing[];
  public waysEnum = FiveWaysToWellbeing;

  constructor() {}

  ngOnInit() {
    if (!this.fiveWays) {
      this.fiveWays = [];
    }
  }
}
