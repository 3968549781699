import {gql, Apollo} from 'apollo-angular';
import {ApolloQueryResult} from '@apollo/client/core';
import {Component, OnInit} from '@angular/core';

import {MyTimeline, MyTimeline_my_timelineEntries} from './__generated__/MyTimeline';
import {SubscribedComponent} from '../../../../shared/misc/SubscribedComponent';
import {catchError, filter, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {FormBuilder, FormGroup} from '@angular/forms';
import {EMPTY, Observable, Subject} from 'rxjs';
import {FilterActivitiesQuery} from './timeline.component.gql';
import { ProfileSearch, ProfileSearchVariables } from './__generated__/ProfileSearch';

export const getMyTimeline = gql`
  query MyTimeline {
    my {
      id
      timelineEntries {
        id
        createdAt
        user {
          id
          profileImage {
            id
            size320x320Link
          }
          displayAlias
        }
        timelineInteractions {
          id
          __typename
          author {
            id
            displayAlias
            profileImage {
              id
              size320x320Link
            }
          }
        }
        __typename
        ... on TimelineActivityEntry {
          id
          trigger {
            id
            description
            fiveWaysToWellbeings
            reward
            duration
            with {
              id
              displayAlias
              loginName
              profileImage {
                id
                size320x320Link
              }
            }
            timelineImages {
              size800x800Link
              originalLink
            }
            hashTags {
              id
              text
            }
          }
        }
        ... on TimelineBadgeEntry {
          id
          trigger {
            id
            description
            name
            badgeCategory
            badgeType
            iconName
          }
        }
        ... on TimelineLevelUpEntry {
          id
          trigger {
            id
            name
          }
        }
        ... on TimelinePlanningEntry {
          id
          trigger {
            id
            description
            appointment
          }
        }
        ... on TimelineQuestEntry {
          id
          trigger {
            id
            name
            description
            fiveWaysToWellbeings
            pointReward {
              id
              amount
            }
          }
        }
      }
    }
  }
`;

export type Entry = MyTimeline_my_timelineEntries;

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent extends SubscribedComponent implements OnInit {
  public recentEntries: Entry[] = [];

  public formFilter: FormGroup;

  public mainLeaderBoard!: Observable<any>;

  private initialFormValue = {
    search: '',
    discriminator: 'All',
  };

  placeHolders = {
    'All' : 'Suche in der Beschreibung',
    'HasPhotos' : 'Suche in der Beschreibung',
    'WithUser' : 'Geben Sie Ihren Benutzernamen ein',
    'Improvement' : '1, 2, 3, 4 oder 5'
  }

  filterEntries = [];

  constructor(private apollo: Apollo, private fb: FormBuilder) {
    super();
    this.formFilter = this.fb.group(this.initialFormValue);
    this.apollo
      .watchQuery<MyTimeline>({
        query: getMyTimeline,
      })
      .valueChanges.pipe(
        filter((ret) => !!ret.data.my),
        map((ret: ApolloQueryResult<MyTimeline>) => ret.data.my!.timelineEntries),
        takeUntil(this.onDestroy),
      )
      .subscribe((entries) => {
        this.recentEntries = entries;
      });
  }

  ngOnInit() {
    const formObservableWithDefaultValue = this.formFilter.valueChanges.pipe(startWith(this.initialFormValue));

    this.mainLeaderBoard = formObservableWithDefaultValue.pipe(
      switchMap((formValue: any) => {
        if (formValue.search === '' && formValue.discriminator === 'All') {
          return EMPTY;
        }
        return this.apollo
          .watchQuery<ProfileSearch, ProfileSearchVariables>({
            query: FilterActivitiesQuery,
            variables: {
              search: formValue.search,
              discriminator: formValue.discriminator,
            },
          })
          .valueChanges.pipe(
            catchError(() => { throw EMPTY; }),
            map((result: ApolloQueryResult<ProfileSearch>) => {
              return result.data.profileSearch;
            }),
          );
      }),
    );
  }
}
