import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-h-c-f-header-title',
  templateUrl: './h-c-f-header-title.component.html',
  styleUrls: ['./h-c-f-header-title.component.scss'],
})
export class HCFHeaderTitleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
