import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EmbedVideoService} from 'src/app/services/embed-video.service';
import {LayoutService, WindowMode} from '../../../../services/layoutService/layout.service';
import {takeUntil} from 'rxjs/operators';
import {SubscribedComponent} from '../../../misc/SubscribedComponent';

@Component({
  selector: 'app-video-help-dialog',
  templateUrl: './video-help-dialog.component.html',
  styleUrls: ['./video-help-dialog.component.scss'],
})
export class VideoHelpDialogComponent extends SubscribedComponent {
  public videoPlayerWidth: number = 400;
  public videoPlayerheight: number = 350;
  public yt_iframe_html: any;

  constructor(
    public dialogRef: MatDialogRef<VideoHelpDialogComponent>,
    private embedService: EmbedVideoService,
    private layout: LayoutService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
    this.layout.windowMode.pipe(takeUntil(this.onDestroy)).subscribe((windowMode: WindowMode | undefined) => {
      if (windowMode === WindowMode.MOBILE) {
        this.videoPlayerWidth = window.innerWidth * 0.65;
        this.videoPlayerheight = this.videoPlayerWidth - 50;
      }
      this.yt_iframe_html = this.embedService.embed(this.data.videoUrl, {
        attr: {width: this.videoPlayerWidth, height: this.videoPlayerheight},
      });
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
