<div class="host" 
  [matTooltip]="showDescription ? '' : badge?.badge.description + '\n' + badge?.badge?.percentage + '% der Nutzer haben es'" 
  [matTooltipDisabled]="hideTooltip"
>
  <div class="badge-name-column">
    <button 
      [ngClass]="badgeClass" 
      [class.badge-available]="badge?.isUnlocked && !badge?.isSelected"
      [class.badge-selected]="badge?.badge?.isSelected"
      class="icon-wrap"
      (click)="badge?.isUnlocked ? selectBadge() : ''"
    >
      <img 
          *ngIf="badgeImagePath; else icon"
          [src]="badgeImagePath" 
          alt="" width="31px" height="31px"
      />
      <ng-template #icon>
        <ng-container *ngIf="badge?.badge.iconName === 'fly'; then brandIcon; else defaultIcon"></ng-container>
        <ng-template #brandIcon>
          <fa-icon [icon]="['fab', badge?.badge.iconName]" size="2x"></fa-icon>
        </ng-template>
        <ng-template #defaultIcon>
          <fa-icon [icon]="['fas', badge?.badge.iconName]" size="2x"></fa-icon>
        </ng-template>
      </ng-template>
    </button>
  </div>
  <div [ngClass]="badgeClass" class="title block-text-font-small">{{ badge?.badge.rarity }}</div>
  <div [ngClass]="badgeClass" class="title block-text-font-small">{{ badge?.badge.name }}</div>
  <div *ngIf="showDescription" [ngClass]="badgeClass" class="title block-text-font-small">{{ badge?.badge.description }}</div>
</div>
