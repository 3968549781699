import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache, split} from '@apollo/client/core';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {TranslateLoader, TranslateModule, TranslateStore} from '@ngx-translate/core';

import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {AuthenticationInterceptors} from './services/interceptors/authentication.interceptor';
import {SharedModule} from './shared/shared.module';
import {CoreModule} from './core/core.module';
import {AppComponent} from './app.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {OperationDefinitionNode} from 'graphql';
import {SnackBarService} from './services/snackbar-service/snackbar-service';
import {AuthenticationService} from './services/authenticationService/authentication.service';
import {AchievementDialogComponent} from './services/reward-unlocked-service/achievement-dialog/achievement-dialog.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment.prod';
import {AppErrorHandler} from './app.error-handler';
import {MatomoModule} from 'ngx-matomo';
import {LevelUpDialogComponent} from './services/level-up-service/level-up-dialog/level-up-dialog.component';
import {MatomoHelperService} from './services/matomo-helper.service';

registerLocaleData(localeDe);

@NgModule({
  imports: [
    BrowserModule,
    SharedModule,
    CoreModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatomoModule,
  ],
  declarations: [AppComponent, AchievementDialogComponent, LevelUpDialogComponent],
  providers: [
    {provide: ErrorHandler, useClass: AppErrorHandler},
    TranslateStore,
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptors,
      multi: true,
      deps: [AuthenticationService, SnackBarService, MatomoHelperService],
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// ===================================================
// AoT requires an exported functions for factories
// ===================================================
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function createApollo(httpLink: HttpLink) {
  // Create an http link:
  const http = httpLink.create({});

  // Create a WebSocket link:
  const ws = new WebSocketLink({
    uri: `ws${location.origin.substring(4)}/graphql`,
    options: {
      reconnect: true,
      lazy: true,
    },
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({query}) => {
      const {kind, operation} = getMainDefinition(query) as OperationDefinitionNode;
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    ws,
    http,
  );
  return {
    link,
    cache: new InMemoryCache({
      possibleTypes: {
        TodoListEntry: ['IndividualPlanning', 'TeamActivityEntry', 'QuestEntry'],
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  };
}
