import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {filter, mergeMap, switchMap} from 'rxjs/operators';

const url = '/images/uploadfiles?type=';

export enum ImageType {
  profile = 'profile',
  timeline = 'timeline',
}

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) {}

  public uploadBlobByObjectUrl(objectUrl: string, imageType: ImageType): Observable<UploadProgress> {
    return this.scaleImage(1920, 1080, objectUrl).pipe(
      mergeMap((blob) => {
        return this.uploadBlob(blob, imageType);
      }),
    );
  }

  private uploadBlob(file: Blob, imageType: ImageType): Observable<UploadProgress> {
    const formData: FormData = new FormData();
    formData.append('files', file);

    const req = new HttpRequest('POST', url + imageType, formData, {
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      filter((e) => e.type === HttpEventType.UploadProgress || e instanceof HttpResponse),
      switchMap((e) => {
        if (e.type === HttpEventType.UploadProgress) {
          if (!e.total) {
            return of({progress: 0});
          }
          const percentDone = Math.round((100 * e.loaded) / e.total);
          return of({progress: percentDone});
        } else {
          return of({progress: 100, complete: true, id: (e as HttpResponse<string[]>).body});
        }
      }),
    );
  }

  scaleImage(maxWidth: number, maxHeight: number, objectUrl: string): Observable<Blob> {
    // Get the image from the blob url
    const img = new Image();
    img.src = objectUrl;

    // The image is only ready for conversion after it has been loaded. the img has a onload callback, which we don't want to use directly,
    // but wrapped in an rxjs observable
    return new Observable<HTMLImageElement>((observer) => {
      img.onload = () => {
        observer.next(img);
        observer.complete();
      };
    }).pipe(
      switchMap((image: HTMLImageElement) => {
        // After the Image has been loaded, we start our scaling and compression routine

        // Create a canvas to draw on
        const elem = document.createElement('canvas');
        const ctx = elem.getContext('2d');
        if (ctx == null) {
          throw new Error(`Couldn't receive scaling context`);
        }
        // Initialize the element with the image data, in case we dont need to resize, but want to compress anyway
        elem.height = image.height;
        elem.width = image.width;

        // Scale the image, if needed, so that the image fits the height of the given frame
        if (image.height > maxHeight) {
          const scalingFactor = maxHeight / elem.height;
          elem.height = elem.height * scalingFactor;
          elem.width = elem.width * scalingFactor;
        }

        // If after scaling using the height, the width is still to large for the frame, scale again
        if (elem.width > maxWidth) {
          const scalingFactor = maxWidth / elem.width;
          elem.height = elem.height * scalingFactor;
          elem.width = elem.width * scalingFactor;
        }

        // Draw the (scaled) image onto the canvas and receive the blob data
        ctx.drawImage(image, 0, 0, elem.width, elem.height);

        // Again the toBlob function only offers a callback, we wrap into an observable
        return new Observable<Blob>((observer) => {
          ctx.canvas.toBlob(
            (blob: Blob | null) => {
              if (blob == null) {
                throw new Error(`Blob mustn't be null`);
              }
              observer.next(blob);
              observer.complete();
            },
            'image/jpeg',
            0.95,
          );
        });
      }),
    );
  }
}

export interface UploadProgress {
  progress: number;
  complete?: boolean;
  id?: string[];
}
