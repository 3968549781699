import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-h-c-f-header-button',
  templateUrl: './h-c-f-header-button.component.html',
  styleUrls: ['./h-c-f-header-button.component.scss'],
})
export class HCFHeaderButtonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
