import {Component, Input, OnInit} from '@angular/core';
import {TeamMainDataFragment} from '../../graph-ql/__generated__/TeamMainDataFragment';

@Component({
  selector: 'app-team-view',
  templateUrl: './team-view.component.html',
  styleUrls: ['./team-view.component.scss'],
})
export class TeamViewComponent implements OnInit {
  @Input() team!: TeamMainDataFragment;

  constructor() {}

  ngOnInit() {}
}
