import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthenticationService} from 'src/app/services/authenticationService/authentication.service';
import {GameTenantKind} from '__generated__/globalTypes';
import {MatomoHelperService} from '../../../services/matomo-helper.service';
import {UserViewFragment} from './__generated__/UserViewFragment';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss'],
})
export class UserViewComponent {
  @Output() showEditMode = new EventEmitter<boolean>();
  @Input() user!: UserViewFragment;
  @Input() showEditButton: boolean = false;
  public gameTenantKind: GameTenantKind | undefined;
  hostUrl = ''; 

  constructor(private matomo: MatomoHelperService, private authenticationService: AuthenticationService) {
    this.gameTenantKind = authenticationService.gameTenantKind;
  }

  ngOnInit(){
    this.hostUrl = window.location.hostname;
  }

  public handleEdit() {
    this.matomo.logStartForm('my-onya-profile');
    this.showEditMode.emit(true);
  }
}
