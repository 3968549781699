import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-editable-section-header',
  templateUrl: './editable-section-header.component.html',
  styleUrls: ['./editable-section-header.component.scss'],
})
export class EditableSectionHeaderComponent implements OnInit {
  @Output()
  onEditClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  editClick() {
    this.onEditClick.emit();
  }
}
