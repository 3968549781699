import {Apollo} from 'apollo-angular';
import {Component, ElementRef, forwardRef, Injector, OnInit, ViewChild} from '@angular/core';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';

import {map, startWith, take} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {HashtagFragment} from '../../../../graph-ql/__generated__/HashtagFragment';
import {GetAutocompleteHashtags} from './__generated__/GetAutocompleteHashtags';
import {GetAutocompleteHashtagQuery} from './hashtag-picker.component.gql';

@Component({
  selector: 'app-hashtag-picker',
  templateUrl: './hashtag-picker.component.html',
  styleUrls: ['./hashtag-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HashtagPickerComponent),
      multi: true,
    },
  ],
})
export class HashtagPickerComponent extends AppControlValueAccessorDirective implements OnInit {
  public selectedHashtags: BehaviorSubject<HashtagFragment[]> = new BehaviorSubject<HashtagFragment[]>([]);
  public filteredAvailableHashtags: Observable<(HashtagFragment | null)[] | null>;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public formCtrl = new FormControl();
  private availableHashtagFragments: HashtagFragment[] = [];
  @ViewChild('auto')
  private matAutocomplete!: MatAutocomplete;
  @ViewChild('hashtagInput')
  private hashtagInput!: ElementRef<HTMLInputElement>;
  private allHashtagFragments: HashtagFragment[] = [];

  constructor(protected injector: Injector, private apollo: Apollo) {
    super();

    this.filteredAvailableHashtags = this.formCtrl.valueChanges.pipe(
      startWith(null),
      map((input: string | HashtagFragment | null) => {
        if (this.availableHashtagFragments) {
          return this.availableHashtagFragments.filter((available) => {
            if (!available) {
              return false;
            }

            if (this.hashtagInput.nativeElement.value !== '') {
              if (typeof input === 'string') {
                return (available.text as string).toLowerCase().includes(input.toLowerCase());
              }
            }

            return false;
          });
        }
        return [];
      }),
    );
  }

  ngOnInit() {
    if (this.isDisabled) {
      this.formCtrl.disable();
    }

    this.apollo
      .watchQuery<GetAutocompleteHashtags>({query: GetAutocompleteHashtagQuery})
      .valueChanges.pipe(
        map((ret) => {
          const hashtags: HashtagFragment[] = ret.data.allAutocompleteHashtags.map((x) => {
            return {
              __typename: 'HashTag',
              id: x.id,
              text: x.text,
            } as HashtagFragment;
          });
          this.availableHashtagFragments = hashtags;

          return hashtags;
        }),
        take(1),
      )
      .subscribe((hashtags) => {
        this.allHashtagFragments = hashtags;
      });

    this.selectedHashtags.subscribe((selectedHashtagFragments) => {
      this.notifyOnChange(selectedHashtagFragments.map((selected) => selected.text));

      if (this.allHashtagFragments) {
        this.availableHashtagFragments = this.allHashtagFragments.filter((hashtag) => {
          if (!hashtag) {
            return false;
          }

          return !selectedHashtagFragments.includes(hashtag);
        });
      }
    });
  }

  public add(event: MatChipInputEvent) {
    if (!event.value) {
      return;
    }

    this.selectedHashtags.next([
      ...this.selectedHashtags.value,
      {
        __typename: 'HashTag',
        id: '',
        text: event.value,
      },
    ]);

    const input = event.input;
    if (input) {
      input.value = '';
    }
  }

  public selected(event: MatAutocompleteSelectedEvent) {
    this.selectedHashtags.next([...this.selectedHashtags.value, event.option.value]);
    this.hashtagInput.nativeElement.value = '';
    this.formCtrl.setValue(null);
  }

  public remove(hashtag: HashtagFragment) {
    this.selectedHashtags.next(this.selectedHashtags.value.filter((selected) => selected !== hashtag));
  }

  writeValue(obj: any): void {
    if (obj) {
      this.selectedHashtags.next(obj);
    } else {
      this.selectedHashtags.next([]);
    }
  }
}
