import {Apollo, gql} from 'apollo-angular';
import {Injectable} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {LevelUpDialogComponent} from './level-up-dialog/level-up-dialog.component';
import {LevelUp} from './__generated__/LevelUp';

const levelUpSubscription = gql`
  subscription LevelUp {
    levelUp {
      name
      description
      infotainmentRewards {
        name
        description
        file {
          link
        }
      }
      unlocksQuests {
        name
        description
      }
      userTitleRewards {
        titelMaennlich
        titelWeiblich
        titelNeutrum
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class LevelUpService {
  private initialized = false;
  private disconnectSubscription = new Subject<boolean>();

  constructor(private apollo: Apollo, private dialog: MatDialog) {}

  public connect(): void {
    if (this.initialized) {
      return;
    }

    this.apollo
      .subscribe<LevelUp>({
        query: levelUpSubscription,
      })
      .pipe(takeUntil(this.disconnectSubscription))
      .subscribe(
        (e) => {
          if (e && e.data && e.data.levelUp) {
            const dialogRef = this.dialog.open(LevelUpDialogComponent, {
              width: '350px',
              data: e.data.levelUp,
            });
          }
        },
        (err) => {
          this.initialized = false;
          return err;
        },
      );

    this.initialized = true;
  }

  public disconnect(): void {
    this.initialized = false;
    this.disconnectSubscription.next(true);
  }
}
