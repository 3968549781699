import {gql} from 'apollo-angular';

export const notificationsQuery = gql`
  query Notifications {
    my {
      id
      emailIsVerified
      news {
        id
        title
        content
      }
      surveyNotifications {
        survey {
          id
          kind
          surveyURL
        }
        round {
          id
          startsAt
          endedAt
        }
      }
    }
  }
`;
