<div class="editor-and-preview">
  <div class="editor" *ngIf="showEditor">
    <div>{{ 'IMAGE_EDITOR.EDITOR' | translate }}</div>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="1"
      [resizeToWidth]="1920"
      [roundCropper]="true"
      [onlyScaleDown]="true"
      outputType="file"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
      style="max-height: 33vh"
      [style.display]="cropperReady ? null : 'none'"
    ></image-cropper>
  </div>
  <div class="preview">
    <ng-container *ngIf="innerValue">
      <div>{{ 'IMAGE_EDITOR.CROPPED_PREVIEW' | translate }}</div>
      <div class="preview-image-container">
        <app-spinner *ngIf="isUploading"></app-spinner>
        <img class="crop-preview" [src]="innerValue.url | safeUrl" [style.background-color]="innerValue.color ? innerValue.color : 'transparent'" />
      </div>
      <div>
        <button *ngIf="innerValue.complete === FileReporterCompleteStatus.NotInitated" mat-button type="button" (click)="uploadImage()">
          {{ 'IMAGE_EDITOR.CONFIRM_SELECTION' | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>

<div class="image-button-container">
  <button
    *ngIf="avatarpickerEnabled && !showEditor"
    class="add-image"
    mat-button
    type="button"
    [disabled]="isUploading"
    (click)="openAvatarChooser()"
  >
    {{ (innerValue ? 'IMAGE_EDITOR.CHANGE_AVATAR' : 'IMAGE_EDITOR.ADD_AVATAR') | translate }}
  </button>
  <!-- <button class="add-image" [disabled]="isUploading" mat-button type="button" (click)="addImage()">
    {{ (innerValue ? 'IMAGE_EDITOR.CHANGE_IMAGE' : 'IMAGE_EDITOR.ADD_IMAGE') | translate }}
  </button> -->
</div>

<ng-container *ngIf="innerValue">
  <button
    *ngIf="innerValue.complete === FileReporterCompleteStatus.NotInitated"
    class="cancle-image"
    [disabled]="isUploading"
    mat-button
    type="button"
    (click)="cancelImage()"
  >
    {{ 'IMAGE_EDITOR.CANCLE_IMAGE' | translate }}
  </button>
</ng-container>

<!--Hidden File Input-->
<input #File (change)="onFileAdded($event)" style="display: none" type="file" accept="image/*" />
