import {GameTenantKind} from './../../../../../../../../__generated__/globalTypes';
import {Component, Input, OnInit} from '@angular/core';
import {AbstractNavButtonDirective} from '../abstract-nav-button';

@Component({
  selector: 'app-nav-raised-button',
  templateUrl: './nav-raised-button.component.html',
  styleUrls: ['./nav-raised-button.component.scss'],
})
export class NavRaisedButtonComponent extends AbstractNavButtonDirective implements OnInit {
  @Input() gameTenantKind: GameTenantKind | undefined;
  constructor() {
    super();
  }

  ngOnInit() {}
}
