<div class="avatar-picker-wrapper">
  <div class="options">
    <div class="colors-container">
      <div 
        *ngFor="let color of colors"
        (click)="selectedAvatar.color = color"
        [class.active]="selectedAvatar?.color === color" 
        [style.background-color]="color" 
        class="dot"
      ></div>
    </div>
    
    <div class="avatar-container">
      <app-avatar-item 
        *ngFor="let avatar of availableAvatars | async" 
        (click)="selectedAvatar.avatar = avatar"
        [avatar]="avatar"
        [class.active]="selectedAvatar?.avatar?.id === avatar.id"
        class="avatar-option"
      ></app-avatar-item>
    </div>
  </div>
  <app-avatar-item
    [backgroundColor]="selectedAvatar?.color"
    [avatar]="selectedAvatar?.avatar" 
    class="preview"
  ></app-avatar-item>
</div>

<app-raised-button (onClick)="dialogRef.close(selectedAvatar)">
  {{ 'IMAGE_EDITOR.CONFIRM_AVATAR' | translate }}
</app-raised-button>
<app-raised-button (onClick)="dialogRef.close()">
  {{ 'IMAGE_EDITOR.CANCEL_AVATAR' | translate }}
</app-raised-button>
