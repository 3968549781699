<h3>
  {{ 'PENDING_INVITATIONS.TITLE' | translate }}
</h3>
<ng-container *ngIf="(invitations | async).length > 0; else noPendingInvitations">
  <ul>
    <li *ngFor="let request of invitations | async; index as i" class="invitation-request-list-item">
      <div>
        <div class="name">{{ request.team.displayAlias }}</div>
        <div class="display-alias-font">{{ '@' + request.team.teamname }}</div>
      </div>
      <div class="button-row">
        <app-icon-button (onClick)="acceptPending(i, true)">
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </app-icon-button>
        <app-icon-button (onClick)="acceptPending(i, false)">
          <fa-icon class="delet" [icon]="['fas', 'times']"></fa-icon>
        </app-icon-button>
      </div>
    </li>
  </ul>
</ng-container>
<ng-template #noPendingInvitations>
  <span class="display-alias-font">{{ 'PENDING_INVITATIONS.NO_PENDING' | translate }}</span>
</ng-template>
