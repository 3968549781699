import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Subject, interval, map, repeat, startWith, switchMap, takeUntil } from 'rxjs';
import { SubscribedComponent } from 'src/app/shared/misc/SubscribedComponent';
import { HasNotifications, HasNotifications_hasNotifications } from './__generated__/HasNotifications';

const CheckerQuery = gql`
  query HasNotifications {
    hasNotifications {
      hasNewNotifications
      hasNewMessages 
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class NotifyCheckerService extends SubscribedComponent {

  private _notifySubject = new BehaviorSubject<any | null>(null);

  readonly notify$ = this._notifySubject.asObservable();

  constructor(
    private _apollo: Apollo
  ) {
    super();
  }

  initChecker(){
    interval(10000)
    .pipe(
      switchMap(() => this._apollo.query<HasNotifications>({ query: CheckerQuery })),
      takeUntil(this.onDestroy),
      map(res => res.data.hasNotifications)
    )
    .subscribe((res: HasNotifications_hasNotifications) => {
      this._notifySubject.next(res);
    });
  }
}
