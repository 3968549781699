/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActivitySearchDiscriminatorKind {
  All = "All",
  HasPhotos = "HasPhotos",
  Improvement = "Improvement",
  WithUser = "WithUser",
}

export enum BadgeCategory {
  Other = "Other",
  Secrets = "Secrets",
  Teams = "Teams",
  WaysOfWellbeing = "WaysOfWellbeing",
}

export enum BadgeKind {
  Bronze = "Bronze",
  Diamond = "Diamond",
  Gold = "Gold",
  Platinum = "Platinum",
  Silver = "Silver",
  Simple = "Simple",
}

export enum FiveWaysToWellbeing {
  BeActive = "BeActive",
  Connect = "Connect",
  Give = "Give",
  KeepLearning = "KeepLearning",
  TakeNotice = "TakeNotice",
}

export enum GameTenantKind {
  Company = "Company",
  School = "School",
}

export enum Gender {
  Diverse = "Diverse",
  Female = "Female",
  Male = "Male",
  Unspecified = "Unspecified",
}

export enum InteractionKind {
  Comment = "Comment",
  ToggleOnya = "ToggleOnya",
}

export enum LeaderBoardKind {
  All = "All",
  BeActive = "BeActive",
  CommonTime = "CommonTime",
  Connect = "Connect",
  Give = "Give",
  KeepLearning = "KeepLearning",
  TakeNotice = "TakeNotice",
}

export enum NotificationKind {
  ActivityInteractions = "ActivityInteractions",
  ActivityMention = "ActivityMention",
  Messages = "Messages",
  MonthlySummary = "MonthlySummary",
  NewActivity = "NewActivity",
  TeamInvitation = "TeamInvitation",
  WeeklySummary = "WeeklySummary",
}

export enum SurveyKind {
  AfterEndSurvey = "AfterEndSurvey",
  EndSurvey = "EndSurvey",
  StartSurvey = "StartSurvey",
}

export enum TeamMembershipKind {
  Administrator = "Administrator",
  Member = "Member",
  Owner = "Owner",
}

export interface ChangeTimelineVisibilityInputType {
  activityId: string;
  visibility?: VisibilityInputType | null;
}

export interface CreateActivity {
  description?: string | null;
  duration: number;
  visibility?: string | null;
  wellbeingImprovement: number;
  fiveWaysToWellbeings?: (FiveWaysToWellbeing | null)[] | null;
  hashTags?: string[] | null;
  timelineImages?: string[] | null;
  planningId?: string | null;
  with?: string[] | null;
}

export interface CreateComment {
  timelineEntryId: string;
  text: string;
}

export interface CreateIndividualPlanning {
  description: string;
  appointment?: any | null;
  improvingFactors?: string | null;
  barriers?: string | null;
  visibility?: string | null;
  solution?: string | null;
  hashTags?: string[] | null;
  with?: string[] | null;
  fiveWaysToWellbeings?: FiveWaysToWellbeing[] | null;
}

export interface CreateTeam {
  teamname: string;
  displayAlias: string;
}

export interface CreateTeamPlanning {
  description: string;
  appointment?: any | null;
  improvingFactors?: string | null;
  barriers?: string | null;
  solution?: string | null;
  hashTags?: string[] | null;
  fiveWaysToWellbeings?: FiveWaysToWellbeing[] | null;
}

export interface CreateUserMessages {
  toUserId: string;
  message: string;
}

export interface Register {
  email: string;
  loginName?: string | null;
  password: string;
  displayAlias?: string | null;
  gameTenantId?: string | null;
}

export interface SetNewPassword {
  loginName: string;
  password: string;
  passwordResetCode: string;
}

export interface UpdateTeam {
  displayAlias: string;
  description: string;
  location: string;
  profileImage?: string | null;
}

export interface UpdateUser {
  displayAlias?: string | null;
  description?: string | null;
  location?: string | null;
  selectedBadgeId?: string | null;
  profileImage?: string | null;
  gender?: Gender | null;
  visibility?: string | null;
  profileBackgroundColor?: string | null;
}

export interface UpdateUserNotifications {
  newActivitiesFromTeamMember?: boolean | null;
  activitiesWithMe?: boolean | null;
  weeklySummary?: boolean | null;
  monthlySummary?: boolean | null;
  teamInvitation?: boolean | null;
  myActivitiesInteractions?: boolean | null;
}

export interface VisibilityInputType {
  members?: (string | null)[] | null;
  visibility?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
