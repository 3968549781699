import {Component, OnInit, Input, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss'],
})
export class ImagePopupComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ImagePopupComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {}

  public closeDialog() {
    this.dialogRef.close();
  }
}

export interface DialogData {
  imageUrl: string;
}
