import {Platform} from '@angular/cdk/platform';
import {Component, Input, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatAccordion} from '@angular/material/expansion';
import {Apollo} from 'apollo-angular';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {GameTenantKind} from '__generated__/globalTypes';
import {NotificationService} from '../../../modules/notifications/notification.service';
import {AuthenticationService} from '../../../services/authenticationService/authentication.service';
import {InstallService} from '../../../services/installService/install.service';
import {LayoutService, WindowMode} from '../../../services/layoutService/layout.service';
import {MatomoHelperService} from '../../../services/matomo-helper.service';
import {IosInstallHintComponent} from './ios-install-hint/ios-install-hint.component';
import { NotifyCheckerService } from 'src/app/services/notify-checker/notify-checker.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  SidebarIsOpened: Observable<boolean> = of(true);
  isMobile: boolean = false;
  public windowMode = WindowMode;

  @Input() gameTenantKind: GameTenantKind | undefined;

  @ViewChild(MatAccordion)
  accordion!: MatAccordion;

  hostUrl = ''; 

  public get isInStandaloneMode(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  constructor(
    private apollo: Apollo,
    public layoutService: LayoutService,
    public authService: AuthenticationService,
    public instService: InstallService,
    private matomo: MatomoHelperService,
    private notificationService: NotificationService,
    private platform: Platform,
    private dialog: MatDialog,
    public notifyService: NotifyCheckerService
  ) {
    this.SidebarIsOpened = this.layoutService.showSidebar;
    this.layoutService.windowMode.pipe(map((x) => x === WindowMode.MOBILE)).subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit(){
    this.hostUrl = window.location.hostname;
  }

  closeSideNav() {
    this.layoutService.HideSideNav();
  }

  public closeIfMobile(url: string) {
    this.matomo.logNavigation('sidenav', url);
    if (this.isMobile) {
      this.closeSideNav();
    }
  }

  logout() {
    this.authService.logout();
  }

  showInstallHint() {
    this.matomo.logDialogShown('showiOSInstallHint');
    this.dialog.open(IosInstallHintComponent, {
      width: '320px',
    });
  }
}
