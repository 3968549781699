import {gql} from 'apollo-angular';

import {BadgeFragment} from '../../../../shared/graph-ql/badge-fragment';

export const myBadgesQuery = gql`
  query myBadges {
    my {
      id
      badges {
        badge {
          ...BadgeFragment
        }
        isUnlocked
      }
    }
  }
  ${BadgeFragment}
`;