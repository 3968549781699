import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DatepickerComponent} from '../datepicker/datepicker.component';

@Component({
  selector: 'app-number-input-cva',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true,
    },
  ],
})
export class NumberInputComponent extends AppControlValueAccessorDirective implements OnInit {
  @Input()
  disabled: boolean = false;

  @Input()
  innerValue!: number;

  @Input()
  unit = 'Stück';

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {}

  writeValue(obj: any): void {
    this.innerValue = obj;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
