import {GameTenantKind, TeamMembershipKind} from './../../../../../__generated__/globalTypes';
import {gql, Apollo} from 'apollo-angular';
import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../services/authenticationService/authentication.service';

import {ToggleOnya, ToggleOnyaVariables} from './__generated__/ToggleOnya';
import {Entry, getMyTimeline} from '../../../modules/my-onya/components/timeline/timeline.component';
import {filter, first, take, takeUntil} from 'rxjs/operators';
import {FiveWaysToWellbeing} from '../../../../../__generated__/globalTypes';
import {myBadges_my_badges} from '../../../modules/achievements/components/badges-list/__generated__/myBadges';
import {MyTimeline_my_timelineEntries_TimelineActivityEntry_trigger_timelineImages} from '../../../modules/my-onya/components/timeline/__generated__/MyTimeline';
import {My_my} from '../../../services/authenticationService/__generated__/My';
import {SubscribedComponent} from '../../misc/SubscribedComponent';
import {ImagePopupComponent} from './image-popup/image-popup.component';
import {MatDialog} from '@angular/material/dialog';
import {TeamMember} from '../team-member-picker/team-member-picker.component';
import {GetTeamActivityQuery} from '../../../modules/my-team/components/activities/activities.component.gql';
import {MatomoHelperService} from '../../../services/matomo-helper.service';
import { FormControl } from '@angular/forms';
import { ChangeActivityVisibilityGQ, ChangeActivityVisibilityGQVariables } from './__generated__/ChangeActivityVisibilityGQ';
import { Router } from '@angular/router';

const LikeEntry = gql`
  mutation ToggleOnya($id: ID!) {
    toggleOnya(timelineEntryId: $id)
  }
`;

const VisibilityEntry = gql`
  mutation ChangeActivityVisibilityGQ($message: ChangeTimelineVisibilityInputType!) {
    updateTimelineEntry(data: $message) {
      activityId
      visibility{ members }
      __typename
    }
  }
`;

@Component({
  selector: 'app-timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
})
export class TimelineItemComponent extends SubscribedComponent implements OnChanges {
  @Input()
  public entry!: Entry;
  @Input()
  public showYear: boolean = false;
  public liked: boolean = false;

  public gameTenantKind: GameTenantKind | undefined;
  public onyaAmount: number = 0;
  public commentAmount: number = 0;
  public pointReward?: number;
  public reward?: number;
  public fiveWays: FiveWaysToWellbeing[] = [];
  public hashtags: {text: string}[] = [];
  public timelineImages: MyTimeline_my_timelineEntries_TimelineActivityEntry_trigger_timelineImages[] = [];
  public appointment?: Date;
  public withUsers: TeamMember[] = [];
  public badge?: myBadges_my_badges;
  public duration?: number;

  @Input()
  public interactionDisabled: boolean = false;

  public enableVisibilitySelector = false;

  visibilitySelectorControl = new FormControl({ visibility: 'TeamMembers', members: [] });

  timeout: any;
  isSavingVisibility = false;
  
  constructor(
    public authService: AuthenticationService,
    public apollo: Apollo,
    private dialog: MatDialog,
    private matomo: MatomoHelperService,
    private router: Router
  ) {
    super();
    this.gameTenantKind = authService.gameTenantKind;
  }
  
  ngOnInit(): void { }
  
  public ngOnChanges() {
    this.authService.user
      .pipe(
        filter((usr): usr is My_my => !!usr),
        takeUntil(this.onDestroy),
      )
      .subscribe((usr) => {       
        this.checkVisibilitySelector(usr);
        this.countInteractions(usr);
      });

    this.fiveWays = (this.entry.trigger as any).fiveWaysToWellbeings;
    this.pointReward = (this.entry.trigger as any).pointReward;
    this.reward = (this.entry.trigger as any).reward;
    this.hashtags = (this.entry.trigger as any).hashTags;
    this.timelineImages = (this.entry.trigger as any).timelineImages;
    this.duration = (this.entry.trigger as any).duration;
    this.appointment = new Date((this.entry.trigger as any).appointment);

    if ((this.entry.trigger as any).with) {
      this.withUsers = (this.entry.trigger as any).with;
    }

    if (this.entry?.trigger?.__typename === 'Badge') {
      const badge = this.entry.trigger as any;
      this.badge = {
        isUnlocked: true,
        __typename: 'UserBadge',
        badge: {
          id: (this.entry.trigger as any).id,
          iconName: badge.iconName,
          description: badge.description,
          name: badge.name,
          titelWeiblich: badge.titelWeiblich,
          titelMaennlich: badge.titelMaennlich,
          titelNeutrum: badge.titelNeutrum,
          badgeType: badge.badgeType,
          badgeCategory: badge.badgeCategory,
          sortValue: badge.sortValue,
          rarity: badge.rarity,
          percentage: badge.percentage,
          isSelected: badge.isSelected,
          imagePath: badge.imagePath || null,
          __typename: 'Badge',
        },
      };
    }

    if (!(this.entry.trigger as any)?.['user']) {
      this.authService.user
        .pipe(
          filter((usr) => !!usr),
          first(),
        )
        .subscribe((user) => {
          (this.entry.trigger as any)['user'] = user;
        });
    }
  }

  public changeVibility(){
    clearTimeout(this.timeout);
    this.matomo.logSubmitForm('timeline-item', 'changeVibility');
    this.timeout = setTimeout(() => {
      this.visibilitySelectorControl.valueChanges
      .subscribe((res)=> {
        if(res.visibility === 'SelectedMembers' && !res.members.length) return;
        if(this.isSavingVisibility) return;
        this.isSavingVisibility = true;
        this.apollo
          .mutate<ChangeActivityVisibilityGQ, ChangeActivityVisibilityGQVariables>({
            mutation: VisibilityEntry,
            variables: {
              message: {
                activityId: this.entry.id,
                visibility: res
              }
            }
          })
          .pipe(take(1))
          .subscribe(() => this.isSavingVisibility = false );
        });
    }, 1000);
  }

  public like() {
    this.matomo.logSubmitForm('timeline-item', 'like');
    if (this.interactionDisabled) {
      return;
    }
    this.apollo
      .mutate<ToggleOnya, ToggleOnyaVariables>({
        mutation: LikeEntry,
        variables: {
          id: this.entry.id,
        },
        refetchQueries: [{query: getMyTimeline}, {query: GetTeamActivityQuery}],
      })
      .pipe(take(1))
      .subscribe();
  }

  public ImagePopup(imageUrl: string) {
    this.matomo.logDialogShown('timeline-item', 'open-fullsize-image-in-popup');
    this.dialog.open(ImagePopupComponent, {
      data: {imageUrl: imageUrl},
    });
  }

  trackNavigation(s: string) {
    this.matomo.logNavigation('timeline-item', s);
  }
  
  private checkVisibilitySelector(user: My_my): void {

    if(this.router.url.includes('my-onya')){
      this.enableVisibilitySelector = false;
      return
    }

    if (user.id === this.entry.user.id) {
      this.enableVisibilitySelector = true;
      return;
    }
  
    const isTeamMemberWithPermissions = user.team?.members.some(member => 
      member.user.id === user.id && 
      (member.teamMembershipKind === TeamMembershipKind.Owner || 
       member.teamMembershipKind === TeamMembershipKind.Administrator)
    );
  
    this.enableVisibilitySelector = !!isTeamMemberWithPermissions;
  }
  
  countInteractions(user: My_my): void {
    for (const interaction of this.entry.timelineInteractions) {
      switch (interaction.__typename) {
        case 'Comment':
          this.commentAmount++;
          break;
        case 'Onya':
          this.onyaAmount++;
          this.liked = user.id === interaction.author.id;
          break;
        default:
          break;
      }
    }
  }
}
