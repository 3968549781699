import {Component, Input, OnInit} from '@angular/core';
import {GameTenantKind} from '__generated__/globalTypes';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit {
  @Input() gameTenantKind: GameTenantKind | undefined;

  constructor() {}

  ngOnInit() {}
}
