import {gql} from 'apollo-angular';

export const teamMemberPickerQuery = gql`
  query teamMemberPicker {
    my {
      id
      team {
        id
        members {
          user {
            id
            loginName
            displayAlias
            profileImage {
              id
              size320x320Link
            }
          }
        }
      }
    }
  }
`;
