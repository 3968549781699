<div class="logged-in-content" *ngIf="layout === Layouts.LoggedIn && currentUser | async">
  <app-toolbar *ngIf="(layoutService.windowMode | async) === windowsMode.DESKTOP"></app-toolbar>
  <app-sidenav [gameTenantKind]="gameTenantKind" [ngClass]="gameTenantKind === 'Company' ? 'company-bg' : 'school-bg'">
    <app-toolbar *ngIf="(layoutService.windowMode | async) !== windowsMode.DESKTOP"></app-toolbar>
    <div class="sidenav-content-container">
      <ng-container *ngTemplateOutlet="router"></ng-container>
    </div>
  </app-sidenav>
</div>
<div class="app-content" *ngIf="layout === Layouts.LoggedOut">
  <ng-container *ngTemplateOutlet="router"></ng-container>
</div>

<button id="cookies-preference" type="button" data-cc="show-consentModal">{{ 'preferencesModal.title' | translate }}</button>

<ng-template #router>
  <router-outlet></router-outlet>
</ng-template>
