<div class="title">
  <h3>
    {{ data.titleTextKey | translate }}
  </h3>
  <p>
    {{ data.bodyTextKey | translate }}
  </p>
</div>

<div class="button-row">
  <button mat-raised-button color="primary" (click)="confirm()">
    {{ data.yesTextKey | translate }}
  </button>
  <button mat-raised-button (click)="cancel()">
    {{ data.noTextKey | translate }}
  </button>
</div>
