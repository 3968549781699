import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconConnectComponent} from './five-ways-of-wellbeing/icon-connect/icon-connect.component';
import {IconBeActiveComponent} from './five-ways-of-wellbeing/icon-be-active/icon-be-active.component';
import {IconTakeNoticeComponent} from './five-ways-of-wellbeing/icon-take-notice/icon-take-notice.component';
import {IconLearnComponent} from './five-ways-of-wellbeing/icon-learn/icon-learn.component';
import {IconGiveComponent} from './five-ways-of-wellbeing/icon-give/icon-give.component';

import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {faEnvelope, faKey, fas, IconPack} from '@fortawesome/pro-solid-svg-icons';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {IconImportantComponent} from './icon-important/icon-important.component';

const icons: IconPack = {
  faEnvelope,
  faKey,
};

const comps = [
  IconConnectComponent,
  IconBeActiveComponent,
  IconTakeNoticeComponent,
  IconLearnComponent,
  IconGiveComponent,
  IconImportantComponent,
];

@NgModule({
  imports: [CommonModule, FontAwesomeModule],
  declarations: [...comps],
  exports: [FontAwesomeModule, ...comps],
})
export class IconsModule {
  constructor(private library: FaIconLibrary) {
    library.addIconPacks(fab, fas, far, fal);
    library.addIconPacks(icons);
  }
}
