<div class="header">
  <div class="title-font title">
    {{ 'ACHIEVEMENT_DIALOG.TITLE' | translate }}
  </div>
</div>
<div class="content">
  <div class="scroll-container">
    <ng-container *ngFor="let badge of badges">
      <app-badge [showDescription]="true" [badge]="badge"></app-badge>
    </ng-container>
  </div>
</div>
<div class="footer">
  <app-raised-button (onClick)="closeDialog()" cdkFocusInitial>
    {{ 'ACHIEVEMENT_DIALOG.CLOSE' | translate }}
  </app-raised-button>
</div>
