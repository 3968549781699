<div class="team-menu">
  <h3>{{ 'TEAM.FIND_TEAM_TITLE' | translate }}</h3>
  <p>{{ 'TEAM.FIND_TEAM_TEXT' | translate }}</p>
  <app-nav-raised-button [gameTenantKind]="gameTenantKind" routerLink="/my-team/join" (click)="trackNavigation('/my-team/join')">
    {{ 'TEAM.CREATE_ACTION' | translate }}
  </app-nav-raised-button>
  <h3>{{ 'TEAM.CREATE_TEAM_TITLE' | translate }}</h3>
  <p>{{ 'TEAM.CREATE_TEAM_TEXT' | translate }}</p>
  <app-nav-raised-button [gameTenantKind]="gameTenantKind" routerLink="/my-team/new-team" (click)="trackNavigation('/my-team/new-team')">
    {{ 'TEAM.CREATE_ACTION' | translate }}
  </app-nav-raised-button>
</div>

<app-pending-invitations></app-pending-invitations>
<app-video-help videoUrl="https://www.youtube.com/watch?v=JBldO-cr168" [videoNumber]="2"></app-video-help>
