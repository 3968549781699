import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {InstallPromptEvent} from '../../app.component';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InstallService {
  public installEventSubj: BehaviorSubject<InstallPromptEvent | null> = new BehaviorSubject<InstallPromptEvent | null>(null);
  public installPossible = this.installEventSubj.asObservable().pipe(map((e) => !!e));

  constructor() {}

  public set installEvent(event: InstallPromptEvent) {
    this.installEventSubj.next(event);
  }

  public used() {
    this.installEventSubj.next(null);
  }
}
