<mat-form-field class="example-full-width">
  <input
    matInput
    [min]="minDate"
    [max]="maxDate"
    [matDatepicker]="picker"
    [placeholder]="elementName"
    [(ngModel)]="representedDate"
    (dateChange)="valueChanges()"
    (focus)="picker.open()"
    (click)="picker.open()"
    readonly="true"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker disabled="false"></mat-datepicker>
</mat-form-field>
