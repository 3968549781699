<ng-container *ngIf="fiveWays.includes(waysEnum.Connect)">
  <div class="connect">
    <app-icon-connect></app-icon-connect>
  </div>
</ng-container>
<ng-container *ngIf="fiveWays.includes(waysEnum.Give)">
  <div class="give">
    <app-icon-give></app-icon-give>
  </div>
</ng-container>
<ng-container *ngIf="fiveWays.includes(waysEnum.KeepLearning)">
  <div class="learn">
    <app-icon-learn></app-icon-learn>
  </div>
</ng-container>
<ng-container *ngIf="fiveWays.includes(waysEnum.BeActive)">
  <div class="be-active">
    <app-icon-be-active></app-icon-be-active>
  </div>
</ng-container>
<ng-container *ngIf="fiveWays.includes(waysEnum.TakeNotice)">
  <div class="take-notice">
    <app-icon-take-notice></app-icon-take-notice>
  </div>
</ng-container>
