import {Component, forwardRef, Injector, Input, OnInit} from '@angular/core';
import {AppControlValueAccessorDirective} from '../AppControlValueAccessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'app-checkbox-cva',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends AppControlValueAccessorDirective implements OnInit {
  @Input()
  isChecked!: boolean;

  constructor(protected injector: Injector) {
    super();
  }

  ngOnInit() {}

  writeValue(obj: boolean): void {
    this.isChecked = obj;
  }

  changeCheckbox($event: MatCheckboxChange) {
    this.isChecked = $event.checked;
    this.notifyOnChange(this.isChecked);
    this.notifyOnTouch();
  }

  toggleBox() {
    if (!this.isDisabled) {
      this.isChecked = !this.isChecked;
      this.notifyOnChange(this.isChecked);
      this.notifyOnTouch();
    }
  }
}
