import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {SharedModule} from '../shared/shared.module';
import {NavItemComponent} from './components/sidenav/nav-item/nav-item.component';
import {RouterModule} from '@angular/router';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {ImportantNavItemComponent} from './components/sidenav/important-nav-item/important-nav-item.component';
import {NavItemButtonComponent} from './components/sidenav/nav-button/nav-item-button.component';
import {PortalModule} from '@angular/cdk/portal';
import {IosInstallHintComponent} from './components/sidenav/ios-install-hint/ios-install-hint.component';

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule, PortalModule],
  declarations: [
    SidenavComponent,
    NavItemComponent,
    ToolbarComponent,
    ImportantNavItemComponent,
    NavItemButtonComponent,
    IosInstallHintComponent,
  ],
  exports: [SidenavComponent, NavItemComponent, ToolbarComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CommonModule has already been loaded. Import Core modules in the AppModule only.');
    }
  }
}
