import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AuthenticationService} from '../authenticationService/authentication.service';
import {GraphQLError} from 'graphql';
import {SnackBarService} from '../snackbar-service/snackbar-service';
import {MatomoHelperService} from '../matomo-helper.service';

@Injectable()
export class AuthenticationInterceptors implements HttpInterceptor {
  private isInitialized: boolean = false;

  constructor(
    private authentication: AuthenticationService,
    private notificationService: SnackBarService,
    private matomo: MatomoHelperService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err && err.status === 401) {
          this.authentication.logout();
        } else if (err && (err.status === 504 || err.status === 503)) {
          if (req.url.includes('graphql')) {
            this.notificationService.error('GENERAL.SERVICE_UNAVAILABLE');
            this.authentication.offlineLogout();
          }
        } else if (err && err.error && err.error.errors) {
          (err.error.errors as GraphQLError[]).forEach((error: GraphQLError) => {
            this.matomo.logOtherAction('http-interceptor', 'error-received', error.extensions!.data.problemDetails.code, error);
            this.notificationService.error(`EXCEPTION.${error.extensions!.data.problemDetails.Title}`);
          });
        }
        if (!this.isInitialized) {
          setTimeout(() => {
            const item = document.body.children.item(1);
            if (item !== null) {
              item.remove();
            }
          }, 1500);
        }
        this.isInitialized = true;
        return throwError(err);
      }),
      tap(() => {
        if (!this.isInitialized) {
          setTimeout(() => {
            const item = document.body.children.item(1);
            if (item !== null) {
              item.remove();
            }
          }, 1500);
        }
        this.isInitialized = true;
      }),
    );
  }
}
