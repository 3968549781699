import {Component, Input, OnInit} from '@angular/core';
import {GameTenantKind} from '__generated__/globalTypes';

@Component({
  selector: 'app-important-nav-item',
  templateUrl: './important-nav-item.component.html',
  styleUrls: ['./important-nav-item.component.scss'],
})
export class ImportantNavItemComponent implements OnInit {
  @Input() gameTenantKind: GameTenantKind | undefined;
  @Input()
  routerLink!: string;

  constructor() {}

  ngOnInit() {}
}
