<app-icon-button (onClick)="setPage(current - 1)" [disabled]="current == 1">
  <fa-icon icon="chevron-double-left"></fa-icon>
</app-icon-button>
<div>
  <div>
    <nav aria-label="Pagination">
      <ul class="pagination">
        <li [class.disabled]="current == 1">
          <a href="javascript:;" (click)="setPage(current - 1)" aria-label="Previous"></a>
        </li>
        <li *ngFor="let page of pages" [class.active]="page == current">
          <a href="javascript:;" (click)="setPage(page)"></a>
        </li>
        <li [class.disabled]="current == maxPages">
          <a href="javascript:;" (click)="setPage(current + 1)" aria-label="Next"></a>
        </li>
      </ul>
    </nav>
  </div>
</div>
<app-icon-button (onClick)="setPage(current + 1)" [disabled]="current == maxPages">
  <fa-icon icon="chevron-double-right"></fa-icon>
</app-icon-button>
